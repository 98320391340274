import { BuildingModel } from "types";

import bishopShipworksExteriorImage from "assets/building-upgrade.svg";
import bishopShipworksInteriorImage from "assets/bg-bishop-shipworks.svg";
import bishopShipworksStaffImage from "assets/shipworks-engineer.svg";
import bishopPilotAcademyExteriorImage from "assets/building-pilot-academy.svg";
import bishopPilotAcademyInteriorImage from "assets/bg-bishop-shipworks.svg";
import bishopPilotAcademyStaffImage from "assets/academy-trainer.svg";

interface BuildingData {
  [key: string]: BuildingModel;
}

export const BUILDINGS: BuildingData = {
  bishopShipworks: {
    type: "shipworks",
    name: "Bishop Shipworks",
    area: "bishopCity",
    position: 130,
    exteriorImage: bishopShipworksExteriorImage,
    interiorImage: bishopShipworksInteriorImage,
    staffImage: bishopShipworksStaffImage,
    staffName: "Starship Engineer Hollis",
  },
  bishopPilotAcademy: {
    type: "pilotAcademy",
    name: "Bishop Pilot Academy",
    area: "bishopCity",
    position: 70,
    exteriorImage: bishopPilotAcademyExteriorImage,
    interiorImage: bishopPilotAcademyInteriorImage,
    staffImage: bishopPilotAcademyStaffImage,
    staffName: "Pilot Trailer Conway",
  },
};
