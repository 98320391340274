import creditsImage from "assets/credits.svg";
import Tooltip, {
  CreditsIcon,
  PropertyIcon,
  TooltipBar,
  TooltipBarValue,
  TooltipCredits,
  TooltipDesc,
  TooltipGrade,
  TooltipInfo,
  TooltipName,
  TooltipProperty,
  TooltipRequirement,
  TooltipRequirements,
  TooltipType,
  TooltipValues,
  Trigger,
  ValuesIcon,
} from "components/base/Tooltip";
import {
  getComparisonIndicator,
  getSupplyData,
  getUpgradeComparisonStats,
  getUpgradeData,
  isSupplyItem,
} from "libs/item";
import {
  getBaseStatData,
  getDerivedStatData,
  getPartData,
  getPropertyData,
  getSupplyTypeData,
} from "libs/stats";
import { useSelector } from "react-redux";
import { getCharacter } from "redux/selectors";
import styled from "styled-components";
import {
  CharacterBaseStats,
  CharacterDerivedStats,
  UpgradeComparisonModel,
} from "types";

interface ItemProps {
  slug: string;
  isTooltipAbove?: boolean;
}

interface UpgradeImageWrapperProps {
  color: string;
}

const UpgradeImageWrapper = styled.div<UpgradeImageWrapperProps>`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  background: linear-gradient(#000000, 60%, ${(props) => props.color}85);
  background-blend-mode: darken;
  user-select: none;
`;

const ItemImage = styled.img`
  width: 100%;
  display: block;
  border-radius: 4px;
  overflow: hidden;
  user-select: none;
`;

const ItemIcon = ({ slug, isTooltipAbove = false }: ItemProps) => {
  const {
    data: { totalBaseStats, installedUpgrades },
  } = useSelector(getCharacter);

  const isSupply = !!isSupplyItem(slug);

  const supply = isSupply ? getSupplyData(slug) : null;
  const upgrade = !isSupply ? getUpgradeData(slug) : null;

  if (!!supply) {
    const supplyTypeInfo = getSupplyTypeData(supply.type);
    const propertyInfo = supply.property && getPropertyData(supply.property);
    return (
      <Trigger>
        <ItemImage src={supply.icon} />
        <Tooltip isAbove={isTooltipAbove}>
          <TooltipName>{supply.name}</TooltipName>
          <TooltipType>{`${
            supplyTypeInfo.isConsumable ? `Consumable` : `Non-consumable`
          } Supply`}</TooltipType>
          <TooltipDesc>{supply.description}</TooltipDesc>
          <TooltipInfo>
            {!!propertyInfo && supply.payload && (
              <TooltipProperty>
                <PropertyIcon image={propertyInfo.image} />
                {propertyInfo.name}: {propertyInfo.addFormatter(supply.payload)}
              </TooltipProperty>
            )}
            <TooltipCredits>
              <CreditsIcon image={creditsImage} />
              Credits: {supply.credits}
            </TooltipCredits>
          </TooltipInfo>
        </Tooltip>
      </Trigger>
    );
  }

  if (!!upgrade) {
    const { gradeData, requirements } = upgrade;
    const partData = getPartData(upgrade.part);
    const installedUpgrade = installedUpgrades[upgrade.part];
    const installedStats = !!installedUpgrade
      ? getUpgradeData(installedUpgrade).derivedStats
      : {};
    const comparisonStats = getUpgradeComparisonStats(
      upgrade.derivedStats,
      installedStats
    );

    return (
      <Trigger>
        <UpgradeImageWrapper color={gradeData.color}>
          <ItemImage src={upgrade.icon} />
        </UpgradeImageWrapper>
        <Tooltip isAbove={isTooltipAbove}>
          <TooltipName color={gradeData.color}>{upgrade.name}</TooltipName>
          {!upgrade.isDefault && (
            <TooltipGrade color={gradeData.color}>
              {`${gradeData.name} ${partData.name}`}
            </TooltipGrade>
          )}
          <TooltipRequirements>
            {Object.entries(requirements).map(([key, value]) => {
              const baseStat = key as keyof CharacterBaseStats;
              const reqValue = value as number;
              const baseStatInfo = getBaseStatData(baseStat);

              return (
                <TooltipRequirement key={baseStat} color={baseStatInfo.color}>
                  {`${baseStatInfo.name} required: ${reqValue}`}
                  <TooltipBar color={baseStatInfo.color}>
                    <TooltipBarValue
                      percentage={(totalBaseStats[baseStat] / reqValue) * 100}
                      color={baseStatInfo.color}
                    />
                  </TooltipBar>
                </TooltipRequirement>
              );
            })}
          </TooltipRequirements>
          <TooltipDesc>{upgrade.description}</TooltipDesc>
          <TooltipInfo>
            {Object.entries(comparisonStats).map(([key, value]) => {
              const statSlug = key as keyof CharacterDerivedStats;
              const statComparison = value as UpgradeComparisonModel;
              const statInfo = getDerivedStatData(statSlug);
              return (
                <TooltipValues key={statSlug} color={statInfo.color}>
                  <ValuesIcon image={statInfo.image} color={statInfo.color} />
                  {statInfo.name}: {statInfo.addFormatter(statComparison.value)}{" "}
                  {getComparisonIndicator(statComparison.difference)}
                </TooltipValues>
              );
            })}
            {!upgrade.isDefault && (
              <TooltipCredits>
                <CreditsIcon image={creditsImage} />
                {`Credits: ${upgrade.credits}`}
              </TooltipCredits>
            )}
          </TooltipInfo>
        </Tooltip>
      </Trigger>
    );
  }

  return <></>;
};

export default ItemIcon;
