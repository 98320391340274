import React from "react";
import styled, { keyframes } from "styled-components";

interface DamageProps {
  damageList: DamageItemProps[];
}

interface DamageItemProps {
  damage: number;
}

const damageAnimation = keyframes`
  25% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }
`;

const DamageWrapper = styled.div`
  position: relative;
  height: 36px;
  display: flex;
  justify-content: center;
`;

const DamageValue = styled.div`
  height: 36px;
  font-size: 32px;
  color: #b75f5f;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  animation: ${damageAnimation} 1200ms ease-in-out forwards;
  text-align: center;
`;

const Damage = ({ damageList = [] }: DamageProps) => {
  return (
    <DamageWrapper>
      {damageList.map((damageItem, index) => (
        <DamageValue key={`${index}.${damageItem.damage}`}>
          {damageItem.damage}
        </DamageValue>
      ))}
    </DamageWrapper>
  );
};

export default Damage;
