import { useDispatch, useSelector } from "react-redux";
import { getCharacter, getFight } from "redux/selectors";
import styled, { css } from "styled-components";
import parse from "html-react-parser";

import healthImage from "assets/health.svg";
import {
  default as attackImage,
  default as combatLogsImage,
} from "assets/icon-combatlog.svg";
import escapeImage from "assets/icon-escape.svg";
import startImage from "assets/icon-start.svg";
import shieldImage from "assets/stat-resilience.svg";
import RatingProgress from "components/base/RatingProgress";
import Tooltip, { TooltipName, Trigger } from "components/base/Tooltip";
import { closeCombatLog, openCombatLog } from "redux/actions";
import { CombatLogTypes } from "types";
import { useEffect, useRef } from "react";

interface CombatLogsIconProps {
  image: string;
  isHighlight: boolean;
}

interface LogsContentProps {
  isShowing: boolean;
}

interface LogProps {
  color: string;
  isLatest: boolean;
}

interface LogIconProps {
  image: string;
  color: string;
}

type FighterTypes = "character" | "opponent" | "neutral";

const logTypeIcons: Record<CombatLogTypes, string> = {
  start: startImage,
  attack: attackImage,
  dodge: shieldImage,
  nullify: shieldImage,
  heal: healthImage,
  escape: escapeImage,
};

const fighterTypeColors: Record<FighterTypes, string> = {
  neutral: "#cccccc",
  character: "#baedff",
  opponent: "#ffb3be",
};

const CombatLogs = styled.div`
  background-color: #00000099;
  position: absolute;
  top: 0;
  right: 0;
  border: 1px #43fffa20 solid;
  border-radius: 4px;
  pointer-events: auto;
  display: flex;
`;

const ToggleButton = styled.div`
  display: flex;
  justify-content: end;
  cursor: pointer;
  padding: 10px;
`;

const highlightMixin = css`
  transform: rotate(-90deg);
`;

const CombatLogsIcon = styled.div<CombatLogsIconProps>`
  height: 24px;
  width: 24px;
  background-color: #43fffa;
  mask: url(${(props) => props.image}) no-repeat center;
  transition: all 200ms linear;

  ${(props) => props.isHighlight && highlightMixin}
`;

const LogsContent = styled.div<LogsContentProps>`
  width: 500px;
  border-right: 1px #43fffa20 solid;

  ${(props) => !props.isShowing && hiddenMixin}
`;

const hiddenMixin = css`
  display: none;
`;

const LogListWrapper = styled.div`
  max-height: 150px;
  overflow-y: scroll;
`;

const LogList = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 5px;
  padding: 10px;
`;

const latestMixin = css``;

const Log = styled.div<LogProps>`
  color: ${(props) => props.color};
  ${(props) => props.isLatest && latestMixin};
  display: flex;
  gap: 5px;
  align-items: center;
`;

const LogIcon = styled.div<LogIconProps>`
  height: 14px;
  width: 14px;
  background-color: ${(props) => props.color};
  mask: url(${(props) => props.image}) no-repeat center;
`;

const LogMessage = styled.div``;

const LogLatest = styled.div``;

const RatingProgressWrapper = styled.div``;

export default function CombatLog() {
  const dispatch = useDispatch();
  const logRef = useRef<HTMLDivElement>(null);

  const { combatLogs, isCombatLogOpen } = useSelector(getFight);
  const { userName } = useSelector(getCharacter);

  // Scroll to newest log on load, and when new logs are added
  useEffect(() => {
    if (logRef.current) {
      logRef.current.scrollIntoView({
        behavior: "auto",
        block: "end",
      });
    }
  }, [logRef, combatLogs]);

  const handleClick = () => {
    if (isCombatLogOpen) {
      dispatch(closeCombatLog());
    } else {
      dispatch(openCombatLog());
    }
  };

  return (
    <CombatLogs>
      <LogsContent isShowing={isCombatLogOpen}>
        <RatingProgressWrapper>
          <RatingProgress />
        </RatingProgressWrapper>
        {combatLogs.length > 0 && (
          <LogListWrapper>
            <LogList>
              {combatLogs.map((log, index, array) => {
                let fighterType: FighterTypes = "neutral";
                if (log.fighter) {
                  fighterType =
                    userName === log.fighter ? "character" : "opponent";
                }
                return (
                  <Log
                    key={index}
                    color={fighterTypeColors[fighterType]}
                    isLatest={index === array.length - 1}
                  >
                    <LogIcon
                      image={logTypeIcons[log.type]}
                      color={fighterTypeColors[fighterType]}
                    />
                    <LogMessage>{parse(log.message)}</LogMessage>
                  </Log>
                );
              })}
            </LogList>
            <LogLatest ref={logRef} />
          </LogListWrapper>
        )}
      </LogsContent>
      <Trigger>
        <ToggleButton onClick={handleClick}>
          <CombatLogsIcon
            image={combatLogsImage}
            isHighlight={isCombatLogOpen}
          />
        </ToggleButton>
        <Tooltip isLeft>
          <TooltipName>
            {isCombatLogOpen ? `Close Combat Log` : ` Open Combat Log`}
          </TooltipName>
        </Tooltip>
      </Trigger>
    </CombatLogs>
  );
}
