import styled from "styled-components";

import BattleRatingSmall from "components/base/BattleRatingSmall";
import ItemIcon from "components/base/ItemIcon";
import Skill from "components/base/Skill";
import { Trigger } from "components/base/Tooltip";
import TooltipStats from "components/base/TooltipStats";
import { BASE_STATS_INFO } from "data/baseStats";
import { BATTLE_RATINGS } from "data/battleRatings";
import { MOBS } from "data/mobs";
import { PARTS } from "data/parts";
import { PILOTS } from "data/pilots";
import { UPGRADES } from "data/upgrades";
import { getUpgradeData, isSupplyItem } from "libs/item";
import { getOpponentSkills } from "libs/skill";
import {
  calculateInitialMobStats,
  getBaseStatData,
  getPartData,
  getSkillData,
} from "libs/stats";
import { useSelector } from "react-redux";
import { getCharacter, OPPONENT_FIGHTER_ACTIONS } from "redux/selectors";
import {
  BattleRatings,
  CharacterBaseStats,
  MobModel,
  PartModel,
  PartTypes,
  PilotModel,
  SkillModel,
  UpgradeModel,
} from "types";
import { COLORS } from "utils/constants";

interface ColorProps {
  color: string;
}

interface IconProps {
  image: string;
  color: string;
}

interface SkillNameProps {
  isDefault: boolean;
}

const DataViewWrapper = styled.div`
  width: 100vw;
  background-color: black;
  color: white;
`;

const SectionWrapper = styled.div`
  margin: 20px;
  background-color: #ffffff10;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 4px;
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  padding: 5px;
  background-color: #ffffff30;
  border-radius: 4px;
`;

const MobImages = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const MobShip = styled.img`
  width: 40px;
  transform: scale(1.1);
`;

const MobPilot = styled.img`
  width: 30px;
`;

const SectionName = styled.div`
  text-transform: uppercase;
  font-weight: 600;
`;

const MobLevel = styled.div`
  color: ${COLORS.LEVELS};
`;

const SectionContent = styled.div`
  display: flex;
  gap: 20px;
  padding: 10px;
`;

const Group = styled.div`
  background-color: #ffffff10;
  border-radius: 4px;
  flex-basis: 300px;
  display: flex;
  flex-direction: column;
`;

const GroupTitle = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  font-size: 18px;
  background-color: #ffffff10;
  padding: 10px;
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px;
`;

const Stat = styled.div<ColorProps>`
  color: ${(props) => props.color};
  text-transform: uppercase;
  font-weight: 600;
`;

const Row = styled.div`
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  flex-grow: 1;
`;

const Part = styled.div<ColorProps>`
  color: ${(props) => props.color};
  display flex;
  gap: 10px;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
`;

const ItemInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
`;

const ItemIconWrapper = styled.div`
  height: 30px;
  min-width: 30px;
  flex-basis: 30px;
`;

const UpgradeReqs = styled.div`
  display: flex;
  justify-content: end;
  gap: 4px;
`;

const UpgradeReq = styled.div<ColorProps>`
  color: ${(props) => props.color};
  font-weight: 600;
  z-index: 1;
  text-align: right;
`;

const EmptyItem = styled.div`
  height: 30px;
  width: 30px;
  background-color: #ffffff10;
  border-radius: 4px;
  flex-basis: 30px;
`;

const Icon = styled.div<IconProps>`
  height: 18px;
  width: 18px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${(props) => props.color};
`;

// All Upgrades List

const UpgradesTitle = styled(SectionTitle)`
  padding: 10px;
`;

const UpgradeRows = styled(Rows)`
  gap: 8px;
`;

const UpgradeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const UpgradeMain = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const UpgradeIconWrapper = styled.div`
  width: 44px;
  height: 44px;
`;

const UpgradeName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
`;

// All Skills List

const SkillsTitle = styled(SectionTitle)`
  padding: 10px;
`;

const SkillRows = styled(Rows)`
  gap: 8px;
`;

const SkillWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const SkillMain = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SkillIconWrapper = styled.div`
  width: 45px;
  height: 45px;
`;

const SkillName = styled.div<SkillNameProps>`
  font-weight: 600;
  text-transform: uppercase;
  ${(props) =>
    props.isDefault &&
    `  color: ${COLORS.UNLOCKED};
`};
`;

const SkillCost = styled.div`
  color: ${COLORS.ENHANCEMENTS};
  font-weight: 600;
  z-index: 1;
  text-align: right;
`;

export default function DataView() {
  const {
    data: { fighterData: characterFighter },
  } = useSelector(getCharacter);

  return (
    <DataViewWrapper>
      {Object.entries(MOBS).map(([key, value]) => {
        const mobSlug = key as string;
        const { name, images, level, drops, installedUpgrades, skills } =
          value as MobModel;

        const { totalBaseStats, derivedStats } =
          calculateInitialMobStats(mobSlug);

        const mobSkills = getOpponentSkills(
          skills,
          derivedStats.total.complete.fasterRecharge
        );

        const mobFighter = {
          name,
          data: {
            stats: derivedStats.current.complete,
            currentBaseStats: totalBaseStats,
            totalBaseStats,
            skills: [],
            skillsRecharge: {},
            skillsValues: {},
            damage: [],
            turnPriority: 0,
          },
          actions: OPPONENT_FIGHTER_ACTIONS,
        };

        return (
          <SectionWrapper>
            <SectionTitle>
              <MobImages>
                <MobShip src={images.ship} />
                <MobPilot src={images.pilot} />
              </MobImages>
              <SectionName>{name}</SectionName>
              <MobLevel>✧{level}</MobLevel>
            </SectionTitle>
            <SectionContent>
              <Group>
                <GroupTitle>Base Stats</GroupTitle>
                <Rows>
                  {Object.values(BASE_STATS_INFO).map((baseStatInfo) => {
                    const baseStatSlug =
                      baseStatInfo.slug as keyof CharacterBaseStats;

                    return (
                      <Trigger key={baseStatSlug}>
                        <Row>
                          <Part color={baseStatInfo.color}>
                            <Icon
                              image={baseStatInfo.image}
                              color={baseStatInfo.color}
                            />
                            {baseStatInfo.name}
                          </Part>
                          <Stat color={baseStatInfo.color}>
                            {totalBaseStats[baseStatSlug]}
                          </Stat>
                        </Row>
                        <TooltipStats
                          baseStat={baseStatSlug}
                          derivedStats={derivedStats.total.complete}
                        />
                      </Trigger>
                    );
                  })}
                </Rows>
              </Group>
              <Group>
                <GroupTitle>Installed Upgrades</GroupTitle>
                <Rows>
                  {Object.entries(installedUpgrades).map(([key, value]) => {
                    const partType = key as PartTypes;
                    const upgradeSlug = value as string;
                    const partInfo = getPartData(partType);
                    const upgradeInfo =
                      !!upgradeSlug && getUpgradeData(upgradeSlug);

                    return (
                      <Row key={partType}>
                        <Part color={partInfo.color}>
                          <Icon image={partInfo.image} color={partInfo.color} />
                          {partInfo.name}
                        </Part>
                        {!!upgradeInfo ? (
                          <ItemInfo>
                            <UpgradeReqs>
                              {Object.entries(upgradeInfo.requirements).map(
                                ([key, value]) => {
                                  const baseStat =
                                    key as keyof CharacterBaseStats;
                                  const reqValue = value as number;
                                  const baseStatInfo =
                                    getBaseStatData(baseStat);

                                  return (
                                    <UpgradeReq
                                      key={baseStat}
                                      color={baseStatInfo.color}
                                    >
                                      {reqValue}
                                    </UpgradeReq>
                                  );
                                }
                              )}
                            </UpgradeReqs>
                            <ItemIconWrapper>
                              <ItemIcon slug={upgradeSlug} />
                            </ItemIconWrapper>
                          </ItemInfo>
                        ) : (
                          <ItemInfo>
                            <UpgradeReqs />
                            <EmptyItem />
                          </ItemInfo>
                        )}
                      </Row>
                    );
                  })}
                </Rows>
              </Group>
              <Group>
                <GroupTitle>Drops</GroupTitle>
                <Rows>
                  {Object.keys(BATTLE_RATINGS).map((key) => {
                    const battleRating = key as BattleRatings;
                    const drop = drops[battleRating];

                    const isUpgrade = !!drop && !isSupplyItem(drop.slug);
                    const upgradeInfo = isUpgrade && getUpgradeData(drop.slug);

                    return (
                      <Row key={key}>
                        <BattleRatingSmall rating={battleRating} iconLeft />
                        {!!drop ? (
                          <ItemInfo>
                            <UpgradeReqs>
                              {!!upgradeInfo &&
                                Object.entries(upgradeInfo.requirements).map(
                                  ([key, value]) => {
                                    const baseStat =
                                      key as keyof CharacterBaseStats;
                                    const reqValue = value as number;
                                    const baseStatInfo =
                                      getBaseStatData(baseStat);

                                    return (
                                      <UpgradeReq
                                        key={baseStat}
                                        color={baseStatInfo.color}
                                      >
                                        {reqValue}
                                      </UpgradeReq>
                                    );
                                  }
                                )}
                            </UpgradeReqs>
                            <ItemIconWrapper>
                              <ItemIcon slug={drop.slug} />
                            </ItemIconWrapper>
                          </ItemInfo>
                        ) : (
                          <ItemInfo>
                            <UpgradeReqs />
                            <EmptyItem />
                          </ItemInfo>
                        )}
                      </Row>
                    );
                  })}
                </Rows>
              </Group>
              <Group>
                <GroupTitle>Skills</GroupTitle>
                <SkillRows>
                  {Object.values(mobSkills).map((value) => {
                    const skillInfo = value as SkillModel;

                    return (
                      <SkillWrapper key={skillInfo.slug}>
                        <SkillMain>
                          <SkillIconWrapper>
                            <Skill
                              skill={skillInfo}
                              attacker={mobFighter}
                              defender={characterFighter}
                              isShowValues
                            />
                          </SkillIconWrapper>
                          <SkillName isDefault={false}>
                            {skillInfo.name}
                          </SkillName>
                        </SkillMain>
                      </SkillWrapper>
                    );
                  })}
                </SkillRows>
              </Group>
            </SectionContent>
          </SectionWrapper>
        );
      })}
      <SectionWrapper>
        <UpgradesTitle>
          <SectionName>All Upgrades</SectionName>
        </UpgradesTitle>
        <SectionContent>
          {Object.values(PARTS).map((value) => {
            const partInfo = value as PartModel;
            return (
              <Group key={partInfo.slug}>
                <GroupTitle>{partInfo.name}</GroupTitle>
                <UpgradeRows>
                  {Object.values(UPGRADES)
                    .filter(
                      (value) =>
                        !value.isDefault && value.part === partInfo.slug
                    )
                    .sort((aValue, bValue) => {
                      const aInfo = getUpgradeData(aValue.slug);
                      const bInfo = getUpgradeData(bValue.slug);
                      return (
                        aInfo.requirementsSum - bInfo.requirementsSum ||
                        aInfo.gradeData.multiplier - bInfo.gradeData.multiplier
                      );
                    })
                    .map((value) => {
                      const upgrade = value as UpgradeModel;
                      const upgradeInfo = getUpgradeData(upgrade.slug);
                      return (
                        <UpgradeWrapper key={upgrade.slug}>
                          <UpgradeMain>
                            <UpgradeIconWrapper>
                              <ItemIcon slug={upgrade.slug} />
                            </UpgradeIconWrapper>
                            <UpgradeName>{upgradeInfo.name}</UpgradeName>
                          </UpgradeMain>
                          <UpgradeReqs>
                            {Object.entries(upgradeInfo.requirements).map(
                              ([key, value]) => {
                                const baseStat =
                                  key as keyof CharacterBaseStats;
                                const reqValue = value as number;
                                const baseStatInfo = getBaseStatData(baseStat);

                                return (
                                  <UpgradeReq
                                    key={baseStat}
                                    color={baseStatInfo.color}
                                  >
                                    {reqValue}
                                  </UpgradeReq>
                                );
                              }
                            )}
                          </UpgradeReqs>
                        </UpgradeWrapper>
                      );
                    })}
                </UpgradeRows>
              </Group>
            );
          })}
        </SectionContent>
      </SectionWrapper>
      <SectionWrapper>
        <SkillsTitle>
          <SectionName>All Skills</SectionName>
        </SkillsTitle>
        <SectionContent>
          {Object.values(PILOTS).map((value) => {
            const pilotInfo = value as PilotModel;
            return (
              <Group key={pilotInfo.name}>
                <GroupTitle>{pilotInfo.name}</GroupTitle>
                <SkillRows>
                  {pilotInfo.skills.map((skill) => {
                    const skillInfo = getSkillData(skill);
                    return (
                      <SkillWrapper>
                        <SkillMain>
                          <SkillIconWrapper>
                            <Skill
                              skill={skillInfo}
                              attacker={characterFighter}
                              isShowValues
                            />
                          </SkillIconWrapper>
                          <SkillName isDefault={!!skillInfo.isDefault}>
                            {skillInfo.name}
                          </SkillName>
                        </SkillMain>
                        <SkillCost>{skillInfo.medallions}</SkillCost>
                      </SkillWrapper>
                    );
                  })}
                </SkillRows>
              </Group>
            );
          })}
        </SectionContent>
      </SectionWrapper>
    </DataViewWrapper>
  );
}
