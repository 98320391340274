import styled, { css } from "styled-components";

import {
  attackAnimation,
  nullifyAnimation,
  reactorCoreAnimation,
  shieldsAnimation,
  targetingSystemAnimation,
  thrusterAnimation,
  weakenedAnimation,
  weaponsPreviewAnimation,
} from "components/styles/animations";
import {
  SKILL_ANIMATIONS,
  SKILL_SHIELDS_ANIMATIONS,
} from "components/styles/skills";
import { PartTypes } from "types";
import { getShipData, getSkillAnimation, hasAnimation } from "libs/stats";
import { getLastWeakenedPartColor } from "libs/fight";

interface AvatarShipProps {
  isPreview?: boolean;
  highlightPart?: PartTypes | null;
  shipData: any;
}

interface SkillAnimationProps {
  $animations: string[];
}

interface ShipProps {
  $isDim: boolean;
}

interface ShipThrustersProps {
  $position: {
    x: number;
    y: number;
  };
  $isMoving: boolean;
  $isDim: boolean;
  $isBright: boolean;
  $animationSpeed: number;
}

interface ShipWeaponsProps {
  $position: {
    x: number;
    y: number;
  };
  $animations: string[];
  $isPreview: boolean;
  $isDim: boolean;
  $isBright: boolean;
  $animationSpeed: number;
}

interface ShipTargetingSystemProps {
  $position: {
    x: number;
    y: number;
  };
  $isDim: boolean;
  $isBright: boolean;
  $animationSpeed: number;
}

interface ShipShieldsWrapperProps {
  $animations: string[];
}

interface ShipShieldsProps {
  $isDim: boolean;
  $isBright: boolean;
  $animationSpeed: number;
}

interface ShipReactorCoreProps {
  $isDim: boolean;
  $isBright: boolean;
  $animationSpeed: number;
}

interface WeakenedPartProps {
  $animations: string[];
  $color: string;
}

const thrusterMixin = css<ShipThrustersProps>`
  animation: ${thrusterAnimation} ${(props) => props.$animationSpeed}ms
    ease-in-out infinite;
`;

const attackMixin = css<ShipWeaponsProps>`
  animation: ${attackAnimation} ${(props) => props.$animationSpeed}ms ease-in
    forwards;
`;

const weakenMixin = css`
  animation: ${weakenedAnimation} 500ms ease-out forwards;
  transform-origin: center center;
`;

const nullifyMixin = css`
  animation: ${nullifyAnimation} 500ms linear forwards;
  transform-origin: center center;
`;

const weaponsPreviewMixin = css`
  animation: 1s ${weaponsPreviewAnimation} linear infinite;
`;

const dimMixin = css`
  filter: saturate(20%) brightness(20%);
`;

const brightMixin = css`
  filter: saturate(130%) brightness(130%);
`;

const AvatarShipWrapper = styled.div`
  height: 100%;
  position: relative;
  aspect-ratio: 1 / 1;
  user-select: none;
`;

const Ship = styled.img<ShipProps>`
  height: 100%;
  ${(props) => props.$isDim && dimMixin}
  transition: filter 200ms ease-out;
`;

const ShipWeapons = styled.img<ShipWeaponsProps>`
  height: 4%;
  position: absolute;
  top: ${(props) => props.$position.y}%;
  left: ${(props) => props.$position.x}%;
  transform: translate3d(0, -50%, 0);
  z-index: -1;
  opacity: 0;
  ${(props) => (hasAnimation(props.$animations, "attack") ? attackMixin : "")};

  ${({ $isPreview }) => $isPreview && weaponsPreviewMixin}
  ${(props) => props.$isDim && dimMixin}
  ${(props) => props.$isBright && brightMixin}
  transition: filter 200ms ease-out;
  pointer-events: none;
`;

const ShipShieldsWrapper = styled.div<ShipShieldsWrapperProps>`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  ${(props) =>
    hasAnimation(props.$animations, "nullify") ? nullifyMixin : ""};
  ${(props) => getSkillAnimation(SKILL_SHIELDS_ANIMATIONS, props.$animations)}
  transition: all 200ms ease-in-out;
  pointer-events: none;
`;

const ShipShields = styled.img<ShipShieldsProps>`
  height: 100%;
  width: 100%;
  animation: ${shieldsAnimation} ${(props) => props.$animationSpeed}ms linear
    infinite;
  z-index: 1;
  ${(props) => props.$isDim && dimMixin}
  ${(props) => props.$isBright && brightMixin}
  transition: filter 200ms ease-out;
  pointer-events: none;
`;

const ShipThrusters = styled.img<ShipThrustersProps>`
  height: 6%;
  position: absolute;
  top: ${(props) => props.$position.y}%;
  right: calc(100% - ${(props) => props.$position.x}%);
  transform: translate3d(0, -50%, 0);
  transform-origin: center right;
  ${(props) => props.$isMoving && thrusterMixin};
  z-index: 0;
  ${(props) => props.$isDim && dimMixin}
  ${(props) => props.$isBright && brightMixin}
  transition: filter 200ms ease-out;
  pointer-events: none;
`;

const ShipTargetingSystem = styled.img<ShipTargetingSystemProps>`
  width: 60%;
  position: absolute;
  top: ${(props) => props.$position.y}%;
  left: ${(props) => props.$position.x}%;
  transform: translate3d(0, -50%, 0);
  transform-origin: center left;
  animation: ${targetingSystemAnimation} ${(props) => props.$animationSpeed}ms
    linear infinite;
  z-index: 1;
  ${(props) => props.$isDim && dimMixin}
  ${(props) => props.$isBright && brightMixin}
  transition: filter 200ms ease-out;
  pointer-events: none;
`;

const ShipReactorCore = styled.img<ShipReactorCoreProps>`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${reactorCoreAnimation} ${(props) => props.$animationSpeed}ms
    ease-out infinite;
  z-index: -1;
  ${(props) => props.$isDim && dimMixin}
  ${(props) => props.$isBright && brightMixin}
  transition: filter 200ms ease-out;
  pointer-events: none;
`;

const ShipReactorCoreSecond = styled(ShipReactorCore)`
  animation-delay: ${(props) => props.$animationSpeed / 2}ms;
  pointer-events: none;
`;

const ShipAnimation = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const WeakenedPart = styled.div<WeakenedPartProps>`
  width: 120px;
  height: 120px;
  opacity: 0;
  border: 8px ${(props) => props.$color} none;
  border-left-style: solid;
  border-radius: 50%;
  filter: brightness(120%);
  ${(props) => (hasAnimation(props.$animations, "weaken") ? weakenMixin : "")};
`;

const PilotSkills = styled.div<SkillAnimationProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  pointer-events: none;
  box-sizing: border-box;
  ${(props) => getSkillAnimation(SKILL_ANIMATIONS, props.$animations)}
`;

export default function AvatarShip({
  isPreview = false,
  highlightPart = null,
  shipData,
}: AvatarShipProps) {
  const {
    ship,
    shipUpgrades,
    damage,
    ui: { animations, isMoving },
  } = shipData;

  const { images, partPositions } = getShipData(ship);

  const weakenedPartColor = getLastWeakenedPartColor(damage);

  return (
    <AvatarShipWrapper>
      <ShipAnimation>
        <WeakenedPart $animations={animations} $color={weakenedPartColor} />
        <PilotSkills $animations={animations} />
      </ShipAnimation>
      <Ship src={images.ship} $isDim={!!highlightPart} />
      <ShipThrusters
        src={shipUpgrades.thrusters.image}
        $position={partPositions.thrusters}
        $animationSpeed={shipUpgrades.thrusters.animationSpeed}
        $isMoving={isMoving || isPreview}
        $isDim={!!highlightPart && highlightPart !== "thrusters"}
        $isBright={highlightPart === "thrusters"}
      />
      <ShipWeapons
        src={shipUpgrades.weapons.image}
        $position={partPositions.weapons}
        $animations={animations}
        $animationSpeed={shipUpgrades.weapons.animationSpeed}
        $isPreview={isPreview}
        $isDim={!!highlightPart && highlightPart !== "weapons"}
        $isBright={highlightPart === "weapons"}
      />
      <ShipTargetingSystem
        src={shipUpgrades.targetingSystem.image}
        $position={partPositions.targeting}
        $animationSpeed={shipUpgrades.targetingSystem.animationSpeed}
        $isDim={!!highlightPart && highlightPart !== "targetingSystem"}
        $isBright={highlightPart === "targetingSystem"}
      />
      <ShipReactorCore
        src={shipUpgrades.reactorCore.image}
        $animationSpeed={shipUpgrades.reactorCore.animationSpeed}
        $isDim={!!highlightPart && highlightPart !== "reactorCore"}
        $isBright={highlightPart === "reactorCore"}
      />
      <ShipReactorCoreSecond
        src={shipUpgrades.reactorCore.image}
        $animationSpeed={shipUpgrades.reactorCore.animationSpeed}
        $isDim={!!highlightPart && highlightPart !== "reactorCore"}
        $isBright={highlightPart === "reactorCore"}
      />
      <ShipShieldsWrapper $animations={animations}>
        <ShipShields
          src={shipUpgrades.shields.image}
          $animationSpeed={shipUpgrades.shields.animationSpeed}
          $isDim={!!highlightPart && highlightPart !== "shields"}
          $isBright={highlightPart === "shields"}
        />
      </ShipShieldsWrapper>
    </AvatarShipWrapper>
  );
}
