import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import BuildingInteriorDisplay from "components/base/BuildingInteriorDisplay";
import EnhanceScreen from "components/ui/EnhanceScreen";
import UpgradeScreen from "components/ui/UpgradeScreen";
import { SWITCH_MAX_LEVEL } from "libs/character";
import { getBuildingData } from "libs/stats";
import { getCharacter } from "redux/selectors";
import { BuildingScreen, BuildingTypes } from "types";
import PilotSkills from "./PilotSkills";
import ShipworksRepair from "./ShipworksRepair";
import ShipworksShopBuy from "./ShipworksShopBuy";
import ShipworksShopSell from "./ShipworksShopSell";
import SwitchPilotScreen from "./SwitchPilotScreen";
import SwitchShipScreen from "./SwitchShipScreen";

interface BuildingInteriorWrapperProps {
  isInsideBuilding: boolean;
  bgImage: string;
}

const showMixin = css`
  opacity: 1;
  pointer-events: auto;
`;

const BuildingInteriorWrapper = styled.div<BuildingInteriorWrapperProps>`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;
  ${(props) => !!props.bgImage && `background-image: url("${props.bgImage}");`}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0;
  pointer-events: none;
  ${(props) => (props.isInsideBuilding ? showMixin : "")};
  transition: all 500ms ease-out;
`;

const BUILDING_SCREENS: Record<BuildingTypes, BuildingScreen[]> = {
  shipworks: [
    {
      slug: "repair",
      name: "Repair",
      component: <ShipworksRepair />,
      dialog:
        "I can't tell if your starship needs heavy repairs or just always looks like that. Anyway, we can replenish your ship's health or you can buy kits to repair your ship on the go.",
    },
    {
      slug: "enhance",
      name: "Enhance",
      component: <EnhanceScreen canEnhance={true} />,
      dialog:
        "Level up your starship's core attributes, which boosts certain calculated stats. Though is it worth using up valuable tokens on this, to put it delicately, piece of space crap?",
    },
    {
      slug: "install",
      name: "Install",
      component: <UpgradeScreen canInstall={true} />,
      dialog:
        "If you've collected upgrades in your inventory, we can install them onto your starship for you. God knows your ship could use it. Oh, did I say that last part out loud?",
    },
    {
      slug: "switch",
      name: "Switch",
      component: <SwitchShipScreen />,
      dialog:
        "Not happy with your choice of starship class? I don't think your ship is what's holding you back, but you might as well try a new class and see. You can only switch ships when level 5 or below.",
      shouldHide: (level) => level > SWITCH_MAX_LEVEL,
    },
    {
      slug: "buy",
      name: "Buy",
      component: <ShipworksShopBuy />,
      dialog:
        "Buy amazing supplies and upgrades here, we have the best prices in the city. Well, the only prices in the city. ...Just gimme your credits.",
    },
    {
      slug: "sell",
      name: "Sell",
      component: <ShipworksShopSell />,
      dialog:
        "Sell your supplies and upgrades to us, if you even have anything of value. We'll give you a fair price for them. ...Hmm, is fair the right word?",
    },
  ],
  pilotAcademy: [
    {
      slug: "train",
      name: "Skills Training",
      component: <PilotSkills canTrain />,
      dialog:
        "What's up, champ? Let's get you trained up on some sweet new pilot skills.",
    },
    {
      slug: "switch",
      name: "Switch",
      component: <SwitchPilotScreen />,
      dialog:
        "Want to switch up your pilot profession? We'll get you set up to learn a new set of pilot skills. You can only switch pilot professions when level 5 or below.",
      shouldHide: (level) => level > SWITCH_MAX_LEVEL,
    },
  ],
};

export default function BuildingInterior() {
  const {
    data: { location },
  } = useSelector(getCharacter);

  let backgroundImage = null;
  let screens = [] as BuildingScreen[];

  if (!!location.building) {
    const buildingInfo = getBuildingData(location.building);
    backgroundImage = buildingInfo.interiorImage;
    screens = BUILDING_SCREENS[buildingInfo.type];
  }

  return (
    <BuildingInteriorWrapper
      isInsideBuilding={!!location.building}
      bgImage={backgroundImage}
    >
      {!!location.building && (
        <BuildingInteriorDisplay
          building={location.building}
          screens={screens}
        />
      )}
    </BuildingInteriorWrapper>
  );
}
