import creditsImage from "assets/credits.svg";

import Button from "components/base/Button";
import {
  ButtonCredits,
  ButtonCreditsIcon,
  DataWrapper,
  ScreenButtonWrapper,
  Section,
  SectionContent,
  SectionContentScroll,
  SectionTitle,
} from "components/base/DataScreen";
import ShipCard from "components/base/ShipCard";
import Tooltip, {
  CreditsIcon,
  TooltipCredits,
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { buySwitchShip } from "redux/actions";
import { getCharacter, getShipUpgrades } from "redux/selectors";
import styled, { css } from "styled-components";
import { ShipModel, ShipTypes } from "types";
import { useImmer } from "use-immer";
import { getAllShipsData, getSwitchCost } from "libs/stats";
import AvatarShip from "./AvatarShip";

interface ShipInfoProps {
  $color: string;
}

interface ShipWrapperProps {
  $color: string;
  $isSelected: boolean;
}

interface SwitchShipState {
  ship: ShipTypes;
}

const Ships = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const hoverMixin = css<ShipWrapperProps>`
  background-color: ${(props) => props.$color}10;
`;

const selectedMixin = css<ShipWrapperProps>`
  background-color: ${(props) => props.$color}20;
  border-color: ${(props) => props.$color};
`;

const ShipWrapper = styled.div<ShipWrapperProps>`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px;
  background-color: #00000020;
  border: 1px ${(props) => props.$color}40 solid;
  transition: all 200ms ease-in;

  :hover {
    ${hoverMixin}
    ${(props) => props.$isSelected && selectedMixin}
  }

  ${(props) => props.$isSelected && selectedMixin}
`;

const Ship = styled.div`
  height: 80px;
  width: 80px;
  background-color: #ffffff10;
  border: 1px #ffffff20 solid;
  border-radius: 4px;
  overflow: hidden;
`;

const ShipInfo = styled.div<ShipInfoProps>`
  color: ${(props) => props.$color};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

const ShipName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px;
`;

const ShipCurrent = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  color: #43fffa;
`;

export default function SwitchShipScreen() {
  const dispatch = useDispatch();
  const {
    data: { ship: currentShip, level },
  } = useSelector(getCharacter);

  const [state, setState] = useImmer<SwitchShipState>({ ship: currentShip });

  const handleClickShip = (ship: ShipTypes) => {
    setState((draft) => {
      draft.ship = ship;
    });
  };

  const allShips = getAllShipsData();
  const isCurrentSelected = currentShip === state.ship;
  const switchCost = getSwitchCost(level);

  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Starship Classes</SectionTitle>
        <SectionContentScroll>
          <Ships>
            {Object.entries(allShips).map(([key, value]) => {
              const shipSlug = key as ShipTypes;
              const shipInfo = value as ShipModel;

              const defaultShipData = {
                ship: shipSlug,
                shipUpgrades: getShipUpgrades(),
                ui: { animation: null, isMoving: false },
              };

              const isSelected = state.ship === shipSlug;
              const isCurrent = currentShip === shipSlug;

              return (
                <ShipWrapper
                  $color={shipInfo.color}
                  $isSelected={isSelected}
                  onClick={() => handleClickShip(shipSlug)}
                >
                  <Ship>
                    <AvatarShip
                      highlightPart={null}
                      shipData={defaultShipData}
                    />
                  </Ship>
                  <ShipInfo $color={shipInfo.color}>
                    <ShipName>{shipInfo.name}</ShipName>
                    {isCurrent && <ShipCurrent>Current</ShipCurrent>}
                  </ShipInfo>
                </ShipWrapper>
              );
            })}
          </Ships>
          <ScreenButtonWrapper>
            <Trigger>
              <Button
                $style="normal"
                $size="medium"
                $disabled={isCurrentSelected}
                onClick={() => {
                  dispatch(buySwitchShip({ ship: state.ship }));
                }}
              >
                Switch Starship
                <ButtonCredits>
                  <ButtonCreditsIcon />
                  {switchCost}
                </ButtonCredits>
              </Button>
              <Tooltip isAbove isLeft>
                <TooltipName>Switch Starship Class</TooltipName>
                <TooltipDesc>
                  Switch to a different starship class. Your core attributes
                  will also be reset and you'll get all your spent medallions
                  back.
                </TooltipDesc>
                <TooltipDesc>
                  It gets more expensive to switch at each higher level, and you
                  can only switch ships up to level 5.
                </TooltipDesc>
                <TooltipCredits>
                  <CreditsIcon image={creditsImage} />
                  {`Credits: ${switchCost}`}
                </TooltipCredits>
              </Tooltip>
            </Trigger>
          </ScreenButtonWrapper>
        </SectionContentScroll>
      </Section>
      <Section>
        <SectionTitle>Selected Starship Class</SectionTitle>
        <SectionContent>
          <ShipCard shipSlug={state.ship} isLarge isSelected />
        </SectionContent>
      </Section>
    </DataWrapper>
  );
}
