import React from "react";
import styled from "styled-components";

import installImage from "assets/install.svg";
import { getInventoryItemAmount, getItemData } from "libs/item";
import ItemIcon from "components/base/ItemIcon";
import { useSelector } from "react-redux";
import { getCharacter } from "redux/selectors";
import {
  ActionButton,
  ActionWrapper,
  Item,
  ItemImageWrapper,
  ItemInfo,
  ItemName,
  ItemWrapper,
} from "./Item";

interface InventoryItemProps {
  slug: string;
  canAction: boolean;
  onClick: (x: string) => void;
}

export const InventoryActionButton = styled(ActionButton)`
  mask: url(${installImage}) no-repeat center;
  mask-size: 60%;
`;

export const ItemQuantity = styled.div`
  font-size: 16px;
  text-align: right;
`;

export default function InventoryItem({
  slug,
  canAction,
  onClick,
}: InventoryItemProps) {
  const {
    data: { inventory },
  } = useSelector(getCharacter);

  const itemData = getItemData(slug);
  const quantity = getInventoryItemAmount(slug, inventory);

  return (
    <Item key={itemData.name} onClick={() => onClick(slug)}>
      <ItemWrapper>
        <ItemImageWrapper>
          <ItemIcon slug={slug} />
          {!!canAction && (
            <ActionWrapper>
              <InventoryActionButton />
            </ActionWrapper>
          )}
        </ItemImageWrapper>
        <ItemInfo>
          <ItemName>{itemData.name}</ItemName>
        </ItemInfo>
      </ItemWrapper>
      <ItemQuantity>x{quantity}</ItemQuantity>
    </Item>
  );
}
