import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { getGameState } from "redux/selectors";
import { getMusicData } from "libs/stats";
import { playNextMusicTrack } from "redux/actions";

const AudioWrapper = styled.div`
  position: absolute;
  background-color: blue;
  top: 20px;
  right: 20px;
`;

export default function MusicPlayer() {
  const dispatch = useDispatch();
  const audioRef = useRef<HTMLAudioElement>(null);

  const { isMusicPlaying, currentTrack, trackChanges } =
    useSelector(getGameState);

  const { audioFile } = getMusicData(currentTrack);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.5;
      if (!!isMusicPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isMusicPlaying, trackChanges]);

  const handleSongEnded = () => {
    dispatch(playNextMusicTrack());
  };

  return (
    <AudioWrapper>
      <audio ref={audioRef} src={audioFile} onEnded={handleSongEnded}></audio>
    </AudioWrapper>
  );
}
