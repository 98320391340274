import React from "react";
import styled from "styled-components";

import { getShopData } from "libs/stats";
import { buyShopItem, sellShopItem } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getCharacter } from "redux/selectors";
import ItemsList from "./ItemsList";

interface CommonProps {
  isUpgrades: boolean;
}

type ConditionalProps =
  | {
      isBuy: true;
      shopSlug?: string;
    }
  | {
      isBuy: false;
      shopSlug?: never;
    };

type ShopProps = CommonProps & ConditionalProps;

export const ShopWrapper = styled.div``;

export default function Shop({ isUpgrades, isBuy, shopSlug = "" }: ShopProps) {
  const dispatch = useDispatch();
  const character = useSelector(getCharacter);
  const {
    data: { inventory },
  } = character;

  const shopType = isUpgrades ? "upgrades" : "supplies";
  let shopItems = [];
  if (isBuy) {
    const shop = getShopData(shopSlug);
    shopItems = shop[shopType] || [];
  } else {
    shopItems = inventory[shopType].map((item) => item.slug);
  }

  const buyItem = (slug: string, quantity: number) => {
    dispatch(buyShopItem({ slug, quantity }));
  };

  const sellItem = (slug: string, quantity: number) => {
    dispatch(sellShopItem({ slug, quantity }));
  };

  const handleClickItem = isBuy ? buyItem : sellItem;

  return (
    <ShopWrapper>
      <ItemsList
        items={shopItems}
        isUpgrades={isUpgrades}
        isInventory={false}
        isBuy={isBuy}
        onClick={handleClickItem}
      />
    </ShopWrapper>
  );
}
