import { SupplyModel, SupplyTypeModel, SupplyTypes } from "types";
import minorRepairKitIcon from "assets/supply-minor-repair-kit.svg";
import mediumRepairKitIcon from "assets/supply-medium-repair-kit.svg";
import majorRepairKitIcon from "assets/supply-major-repair-kit.svg";
import minorRestoreModuleIcon from "assets/supply-minor-restore-module.svg";
import mediumRestoreModuleIcon from "assets/supply-medium-restore-module.svg";
import majorRestoreModuleIcon from "assets/supply-major-restore-module.svg";
import minorExpBoosterIcon from "assets/supply-minor-exp-booster.svg";
import mediumExpBoosterIcon from "assets/supply-medium-exp-booster.svg";
import majorExpBoosterIcon from "assets/supply-major-exp-booster.svg";
import recallRiftIcon from "assets/supply-recall-rift.svg";
import {
  employRepairKitSaga,
  employExpBoosterSaga,
  employRestoreKitSaga,
  employRecallRiftSaga,
} from "redux/sagas/item";

export const DEFAULT_ITEM_MAX_AMOUNT = 99;

export const SUPPLIES: Record<string, SupplyModel> = {
  minor_repair_kit: {
    slug: "minor_repair_kit",
    name: "Minor Repair Kit",
    type: "health",
    description: "A kit that repairs your ship's health slightly.",
    icon: minorRepairKitIcon,
    action: employRepairKitSaga,
    property: "health",
    payload: 25,
    credits: 10,
  },
  medium_repair_kit: {
    slug: "medium_repair_kit",
    name: "Medium Repair Kit",
    type: "health",
    description: "A kit that repairs your ship's health medium...ly.",
    icon: mediumRepairKitIcon,
    action: employRepairKitSaga,
    property: "health",
    payload: 50,
    credits: 25,
  },
  major_repair_kit: {
    slug: "major_repair_kit",
    name: "Major Repair Kit",
    type: "health",
    description: "A kit that heavily repairs your ship's health.",
    icon: majorRepairKitIcon,
    action: employRepairKitSaga,
    property: "health",
    payload: 200,
    credits: 100,
  },
  minor_restore_module: {
    slug: "minor_restore_module",
    name: "Minor Parts Restore",
    type: "integrity",
    description:
      "A module that restores your ship's parts slightly while in battle.",
    icon: minorRestoreModuleIcon,
    action: employRestoreKitSaga,
    property: "integrity",
    payload: 5,
    credits: 50,
  },
  medium_restore_module: {
    slug: "medium_restore_module",
    name: "Medium Parts Restore",
    type: "integrity",
    description: "A module that restores your ship's parts while in battle.",
    icon: mediumRestoreModuleIcon,
    action: employRestoreKitSaga,
    property: "integrity",
    payload: 10,
    credits: 100,
  },
  major_restore_module: {
    slug: "major_restore_module",
    name: "Major Parts Restore",
    type: "integrity",
    description:
      "A module that restores your ship's parts heavily while in battle.",
    icon: majorRestoreModuleIcon,
    action: employRestoreKitSaga,
    property: "integrity",
    payload: 25,
    credits: 250,
  },
  minor_exp_booster: {
    slug: "minor_exp_booster",
    name: "Minor Exp Booster",
    type: "experience",
    description: "A module that boosts your experience by a little bit.",
    icon: minorExpBoosterIcon,
    action: employExpBoosterSaga,
    property: "experience",
    payload: 50,
    credits: 250,
  },
  medium_exp_booster: {
    slug: "medium_exp_booster",
    name: "Medium Exp Booster",
    type: "experience",
    description: "A module that boosts your experience by a medium amount.",
    icon: mediumExpBoosterIcon,
    action: employExpBoosterSaga,
    property: "experience",
    payload: 100,
    credits: 500,
  },
  major_exp_booster: {
    slug: "major_exp_booster",
    name: "Major Exp Booster",
    type: "experience",
    description: "A module that boosts your experience by a large amount.",
    icon: majorExpBoosterIcon,
    action: employExpBoosterSaga,
    property: "experience",
    payload: 200,
    credits: 1000,
  },
  recall_rift: {
    slug: "recall_rift",
    name: "Recall Rift",
    type: "travel",
    description:
      "A portal device for instant travel to your current planet's main city.",
    icon: recallRiftIcon,
    action: employRecallRiftSaga,
    credits: 5000,
  },
};

export const SUPPLY_TYPES: Record<SupplyTypes, SupplyTypeModel> = {
  health: {
    name: "Ship Health",
    inFight: true,
    isConsumable: true,
    maxQuantity: DEFAULT_ITEM_MAX_AMOUNT,
  },
  integrity: {
    name: "Component Integrity",
    inFight: true,
    isConsumable: true,
    maxQuantity: DEFAULT_ITEM_MAX_AMOUNT,
  },
  experience: {
    name: "Experience",
    inFight: false,
    isConsumable: true,
    maxQuantity: DEFAULT_ITEM_MAX_AMOUNT,
  },
  travel: {
    name: "Travel",
    inFight: false,
    isConsumable: false,
    maxQuantity: 1,
  },
};
