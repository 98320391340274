import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import expImage from "assets/exp.svg";
import healthImage from "assets/health.svg";
import Bar from "components/base/Bar";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import { damagedAnimation } from "components/styles/animations";
import { isMaxLevel } from "libs/character";
import { getPilotData, getShipData, hasAnimation } from "libs/stats";
import { getCharacter } from "redux/selectors";
import { COLORS } from "utils/constants";
import AccountWarning from "./AccountWarning";
import QuickActions from "./QuickActions";

interface PilotImageWrapperProps {
  $animations: string[];
}

interface PilotBackgroundProps {
  color: string;
}

const CharacterInfoWrapper = styled.div`
  position: absolute;
  top: 5vh;
  left: 5vh;
  z-index: 6;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
`;

const damagedMixin = css`
  animation: ${damagedAnimation} 300ms ease-in-out forwards;
`;

const PilotImageWrapper = styled.div<PilotImageWrapperProps>`
  height: 100%;
  ${(props) =>
    hasAnimation(props.$animations, "damaged") ? damagedMixin : ""};
  z-index: 1;
  border: 1px #ffffff solid;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PilotBackground = styled.div<PilotBackgroundProps>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: ${(props) => props.color};
  filter: brightness(40%);
`;

const PilotImage = styled.img`
  height: 100%;
`;

const LevelWrapper = styled.div`
  position: absolute;
  z-index: 2;
  bottom: -3px;
  left: -3px;
  transform: translateY(105%);
  width: 100%;
`;

const LevelInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px;
  font-size: 18px;
  color: ${COLORS.LEVELS};
`;

const LevelIcon = styled.div``;

const LevelValue = styled.div``;

const BarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -6px;
`;

const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const HealthBarWrapper = styled(BarWrapper)`
  width: 280px;
`;

const ExperienceBarWrapper = styled(BarWrapper)`
  width: 268px;
`;

const AccountWarningWrapper = styled.div`
  margin-left: 30px;
`;

export default function CharacterInfo() {
  const {
    data: {
      level,
      ship,
      pilot,
      health,
      currentLevelExp,
      nextLevelExp,
      derivedStats,
      ui: { animations },
    },
  } = useSelector(getCharacter);
  const { color } = getShipData(ship);
  const { images: pilotImages } = getPilotData(pilot);

  return (
    <CharacterInfoWrapper>
      <StatusWrapper>
        <PilotImageWrapper $animations={animations}>
          <PilotBackground color={color} />
          <PilotImage src={pilotImages.avatar} />
          <LevelWrapper>
            <Trigger>
              <LevelInfo>
                <LevelIcon>✧</LevelIcon>
                <LevelValue>{level}</LevelValue>
              </LevelInfo>
              <Tooltip>
                <TooltipName>Level</TooltipName>
                <TooltipDesc>{`You are currently at level ${level}. Keep leveling up to strengthen your ship and learn more pilot skills!`}</TooltipDesc>
              </Tooltip>
            </Trigger>
          </LevelWrapper>
        </PilotImageWrapper>
        <BarsWrapper>
          <HealthBarWrapper>
            <Bar
              icon={healthImage}
              number={health}
              maxNumber={derivedStats.current.complete.maxHealth}
              color={COLORS.HEALTH}
              name="Ship Health"
              description="This is how much health your ship currently has. Don't let it drop to zero! You can fully repair your starship at the nearest Shipworks in any city."
            />
          </HealthBarWrapper>
          <ExperienceBarWrapper>
            <Bar
              icon={expImage}
              number={currentLevelExp}
              maxNumber={nextLevelExp}
              color={COLORS.EXPERIENCE}
              name="Experience"
              description="This is how much experience you have in your current level. Gain more experience to level up!"
              isMaxedOut={isMaxLevel(level)}
            />
          </ExperienceBarWrapper>
          <QuickActions />
        </BarsWrapper>
        <AccountWarningWrapper>
          <AccountWarning />
        </AccountWarningWrapper>
      </StatusWrapper>
    </CharacterInfoWrapper>
  );
}
