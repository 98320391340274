import { useDispatch, useSelector } from "react-redux";

import creditsImage from "assets/credits.svg";
import { DataScreen } from "components/base/DataScreen";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import { getBuildingData } from "libs/stats";
import { exitBuilding, switchBuildingScreen } from "redux/actions";
import { getCharacter } from "redux/selectors";
import styled, { css } from "styled-components";
import { BuildingScreen } from "types";
import { COLORS } from "utils/constants";

interface BuildingInteriorDisplayProps {
  building: string;
  screens: BuildingScreen[];
}

interface MenuItemProps {
  isActive: boolean;
}

interface IconProps {
  image: string;
}

export const InteriorWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

export const MenuWrapper = styled.div`
  background-color: #0e4042;
  color: white;
  z-index: 1;
  display: flex;
  opacity: 0.95;
`;

export const BuildingName = styled.div`
  padding: 9px 30px;
  font-size: 28px;
  color: #43fffa;
  text-transform: uppercase;
  font-weight: 600;
  background: #000000;
`;

export const activeMixin = css`
  background-color: #00000020;
  color: #43fffa;
`;

export const MenuItem = styled.div<MenuItemProps>`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  padding: 15px 30px;
  box-sizing: border-box;
  ${(props) => props.isActive && activeMixin};

  &:hover {
    background-color: #00000020;
  }
`;

export const CreditsWrapper = styled.div`
  padding: 15px 30px;
  margin-left: auto;
`;

export const Credits = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${COLORS.CREDITS};
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const CreditsIcon = styled.div<IconProps>`
  height: 18px;
  width: 18px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.CREDITS};
`;

export const ExitButton = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  box-sizing: border-box;
  color: #ffffff;
  padding: 15px 30px;
  background-color: #ffffff20;

  &:hover {
    background-color: #00000020;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  overflow-y: scroll;
`;

export const DataScreenWrapper = styled.div`
  flex-basis: 70%;
`;

export const StaffWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  align-items: stretch;
  align-self: end;
`;

export const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  opacity: 0.95;
`;

export const Dialog = styled.div`
  font-size: 18px;
  background-color: #000000;
  align-self: stretch;
  padding: 30px;
`;

export const StaffName = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #0e4042;
  padding: 15px 30px;
`;

export const StaffImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const InteriorStaff = styled.img`
  display: block;
  width: 90%;
`;

export default function BuildingInteriorDisplay({
  building,
  screens,
}: BuildingInteriorDisplayProps) {
  const dispatch = useDispatch();
  const {
    data: { level, credits, location },
  } = useSelector(getCharacter);

  const { name, staffImage, staffName } = getBuildingData(building);

  // Filter out switch screen if above level 5
  const availableScreens = screens.filter(
    (screen) => !(screen.shouldHide && screen.shouldHide(level))
  );

  const setScreen = availableScreens.find(
    (screen) => screen.slug === location.buildingScreen
  );
  const currentScreen = setScreen ? setScreen : availableScreens[0];

  return (
    <InteriorWrapper>
      <MenuWrapper>
        <BuildingName>{name}</BuildingName>

        {availableScreens.map((screen) => (
          <MenuItem
            key={screen.slug}
            isActive={currentScreen.slug === screen.slug}
            onClick={() => dispatch(switchBuildingScreen(screen.slug))}
          >
            {screen.name}
          </MenuItem>
        ))}

        <CreditsWrapper>
          <Trigger>
            <Credits>
              <CreditsIcon image={creditsImage} />
              {credits}
            </Credits>
            <Tooltip isLeft>
              <TooltipName>Credits Available</TooltipName>
              <TooltipDesc>
                How many credits you have to spend on repairs, stat resets,
                upgrades, and supplies.
              </TooltipDesc>
            </Tooltip>
          </Trigger>
        </CreditsWrapper>
        <ExitButton onClick={() => dispatch(exitBuilding())}>Exit</ExitButton>
      </MenuWrapper>
      <ContentWrapper>
        <DataScreenWrapper>
          <DataScreen>{currentScreen.component}</DataScreen>
        </DataScreenWrapper>
        <StaffWrapper>
          <StaffImageWrapper>
            <InteriorStaff src={staffImage} />
          </StaffImageWrapper>
          <DialogWrapper>
            <StaffName>{staffName}</StaffName>
            <Dialog>
              {!!location.buildingDialog
                ? location.buildingDialog
                : currentScreen.dialog}
            </Dialog>
          </DialogWrapper>
        </StaffWrapper>
      </ContentWrapper>
    </InteriorWrapper>
  );
}
