import { getPropertyData } from "./stats";

export const MAX_LEVEL = 50;
export const BASE_LEVEL_EXP = 1000;
export const SWITCH_MAX_LEVEL = 5;

// LEVELS

export const calculateLevelsGained = (
  currentLevelExp: number,
  expGained: number
): number => {
  const totalExp = currentLevelExp + expGained;
  const levelsGained = Math.floor(totalExp / BASE_LEVEL_EXP);

  return levelsGained;
};

export const calculateUpdatedLevelExp = (
  levelExp: number,
  expGained: number
) => {
  const propertyInfo = getPropertyData("experience");

  const newExp = levelExp + expGained;
  return propertyInfo.rounder(newExp % BASE_LEVEL_EXP);
};

export const getNextLevelExp = (): number => {
  return BASE_LEVEL_EXP;
};

export const getExpToNextLevel = (
  currentLevelExp: number,
  nextLevelExp: number
) => {
  const propertyInfo = getPropertyData("experience");

  return propertyInfo.rounder(nextLevelExp - currentLevelExp);
};

export const isMaxLevel = (level: number): boolean => {
  return level >= MAX_LEVEL;
};
