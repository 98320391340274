import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import ItemIcon from "components/base/ItemIcon";
import { employSupply } from "redux/actions";
import { getCharacter } from "redux/selectors";
import { getSuppliesByType } from "libs/item";

interface ActionItemProps {
  $isDisabled: boolean;
}

const QuickActionsWrapper = styled.div`
  position: absolute;
  left: 90px;
  bottom: -20px;
`;

const Actions = styled.div``;

const disabledMixin = css`
  opacity: 0.3;
  pointer-events: none;
  filter: saturate(0);
`;

const ActionItem = styled.div<ActionItemProps>`
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 200ms ease-in;

  ${(props) => props.$isDisabled && disabledMixin}
`;

export default function QuickActions() {
  const dispatch = useDispatch();
  const {
    data: { inventory, canRecall },
  } = useSelector(getCharacter);

  const travelSupplies = getSuppliesByType(inventory.supplies, "travel");

  return (
    <QuickActionsWrapper>
      {travelSupplies.length > 0 && (
        <Actions>
          {travelSupplies.map((supply) => (
            <ActionItem
              key={supply.slug}
              $isDisabled={!canRecall}
              onClick={() =>
                dispatch(employSupply({ slug: supply.slug, showMessage: true }))
              }
            >
              <ItemIcon slug={supply.slug} />
            </ActionItem>
          ))}
        </Actions>
      )}
    </QuickActionsWrapper>
  );
}
