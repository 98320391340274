import { BATTLE_RATINGS } from "data/battleRatings";
import { DERIVED_STATS } from "data/derivedStats";
import { MOBS } from "data/mobs";
import {
  getBaseStatData,
  getNumberFromDiceRoll,
  getPropertyData,
  rollDice,
} from "libs/stats";
import {
  BattleRatingModel,
  BattleRatings,
  BattleRatingsData,
  CharacterBaseStats,
  CharacterDerivedStats,
  DamageModel,
  FightStatuses,
  MobDialogsModel,
  MobDropModel,
  MobDrops,
  MobModel,
} from "types";
import { roundOneDecimal, roundWhole } from "utils/formatters";

export const BASE_MOB_EXP = 100;
export const LOWER_MOB_EXP_MULTIPLIER = 0.02;
export const HIGHER_MOB_EXP_MULTIPLIER = 0.05;
export const MAX_MOB_EXP = 5000;
export const MOB_CREDITS_PER_LEVEL = 20;

// Chance thresholds
export const MOB_SKILL_THRESHOLD = 0.5;

// DATA

export const getMobData = (slug: string): MobModel => {
  return MOBS[slug];
};

export const getBattleRatingData = (slug: BattleRatings): BattleRatingModel => {
  return BATTLE_RATINGS[slug];
};

// DIALOG

export const getMobShipDialog = (
  dialogs: MobDialogsModel,
  status: FightStatuses
) => {
  const mobDialogs: Record<string, string[] | null> = {
    openingDialog: [dialogs.opening],
    winDialog: [dialogs.win],
    loseDialog: [dialogs.lose],
    fighting: dialogs.tutorial ? dialogs.tutorial.fight : null,
  };

  return mobDialogs[status];
};

export const getMobOverlayDialog = (
  dialogs: MobDialogsModel,
  status: FightStatuses
) => {
  const mobDialogs: Record<string, string[] | null> = {
    preview: dialogs.tutorial ? [dialogs.tutorial.preview] : null,
    winResults: dialogs.tutorial ? [dialogs.tutorial.winResults] : null,
    loseResults: dialogs.tutorial ? [dialogs.tutorial.loseResults] : null,
  };

  return mobDialogs[status];
};

// TURNS

export const getFightTurns = (
  characterTurnPriority: number,
  opponentTurnPriority: number,
  characterAttackSpeed: number,
  opponentAttackSpeed: number
) => {
  const isCharacterCurrentTurn = characterTurnPriority >= opponentTurnPriority;
  let isCharacterNextTurn;

  if (isCharacterCurrentTurn) {
    // Character turn
    isCharacterNextTurn =
      characterTurnPriority >= opponentTurnPriority + opponentAttackSpeed;
  } else {
    // Opponent turn
    isCharacterNextTurn =
      opponentTurnPriority <= characterTurnPriority + characterAttackSpeed;
  }

  return {
    isCharacterCurrentTurn,
    isCharacterNextTurn,
  };
};

export const getNextConsecutiveTurns = (
  attackerTurnPriority: number,
  defenderTurnPriority: number,
  defenderAttackSpeed: number
) => {
  let nextTurns = 0;
  while (attackerTurnPriority >= defenderTurnPriority + defenderAttackSpeed) {
    nextTurns++;
    attackerTurnPriority -= defenderAttackSpeed;
  }
  return nextTurns;
};

// ATTACK VALUES

export const getRegularAttackValues = (
  attackerStats: CharacterDerivedStats,
  defenderStats: CharacterDerivedStats
) => {
  const {
    maxAttackDamage: maxAttackDamageInfo,
    maxWeakenParts: maxWeakenPartsInfo,
  } = DERIVED_STATS;

  let attackDamage = 0;
  let attackWeakenParts = 0;

  // Roll dice for dodge
  const isDodge = doesDodgeHappen(
    attackerStats.attackAccuracy,
    defenderStats.attackEvasion
  );

  // If not dodge, roll for attack damage + weaken parts
  if (!isDodge) {
    const diceRoll = rollDice();

    attackDamage = getNumberFromDiceRoll(
      attackerStats.minAttackDamage,
      attackerStats.maxAttackDamage,
      diceRoll
    );
    attackWeakenParts = getNumberFromDiceRoll(
      attackerStats.minWeakenParts,
      attackerStats.maxWeakenParts,
      diceRoll
    );
  }

  return {
    attackDamage: maxAttackDamageInfo.rounder(attackDamage),
    attackWeakenParts: maxWeakenPartsInfo.rounder(attackWeakenParts),
    isDodge,
  };
};

export const getMultiplierAttackValues = (
  damage: number,
  weakenParts: number,
  attackMultiplier: number
) => {
  const {
    maxAttackDamage: maxAttackDamageInfo,
    maxWeakenParts: maxWeakenpartsInfo,
  } = DERIVED_STATS;

  const attackDamage = damage * (1 + attackMultiplier);
  const attackWeakenParts = weakenParts * (1 + attackMultiplier);

  return {
    attackDamage: maxAttackDamageInfo.rounder(attackDamage),
    attackWeakenParts: maxWeakenpartsInfo.rounder(attackWeakenParts),
  };
};

export const getTotalDamageValuesAfterDefense = (
  attackDamage: number,
  attackWeakenParts: number,
  damageReduction: number,
  weakenPartsReduction: number
) => {
  const {
    maxAttackDamage: maxAttackDamageInfo,
    maxWeakenParts: maxWeakenPartsInfo,
  } = DERIVED_STATS;

  // Calculate end damages after shield reductions
  const damageAfterReduction = Math.max(0, attackDamage - damageReduction);
  const weakenPartsAfterReduction = Math.max(
    0,
    attackWeakenParts - weakenPartsReduction
  );

  // Get actual amount of damage that was reduced by shields
  const damageReduced = Math.min(attackDamage, damageReduction);
  const weakenPartsReduced = Math.min(attackWeakenParts, weakenPartsReduction);

  // If all damage is completely reduced by shields, it's nullified
  let isNullified = false;
  if (
    damageAfterReduction === 0 &&
    weakenPartsAfterReduction === 0 &&
    (damageReduced > 0 || weakenPartsReduced > 0)
  ) {
    isNullified = true;
  }

  return {
    damage: maxAttackDamageInfo.rounder(damageAfterReduction),
    weakenParts: maxWeakenPartsInfo.rounder(weakenPartsAfterReduction),
    damageReduced,
    weakenPartsReduced,
    isNullified,
  };
};

// FIGHT ACTIONS

export const getOpponentBaseStatTarget = (
  baseStatsTargets: (keyof CharacterBaseStats)[],
  characterBaseStats: CharacterBaseStats
): keyof CharacterBaseStats => {
  // Go in order of opponent's targeting, unless the base stat is already 0
  for (const i in baseStatsTargets) {
    if (characterBaseStats[baseStatsTargets[i]] > 0) {
      return baseStatsTargets[i];
    }
  }
  // If all are already 0, just target the first
  return baseStatsTargets[0];
};

export const getLastWeakenedPartColor = (damage: DamageModel[] = []) => {
  let weakenedPartColor = "white";
  const lastDamage = damage[damage.length - 1];
  if (lastDamage && lastDamage.baseStatWeakened) {
    const baseStatInfo = getBaseStatData(lastDamage.baseStatWeakened);
    weakenedPartColor = baseStatInfo.color;
  }

  return weakenedPartColor;
};

export const getHitChance = (
  attackerAccuracy: number,
  defenderEvasion: number
): number => {
  const hitChance = attackerAccuracy / (attackerAccuracy + defenderEvasion);

  return hitChance;
};

export const getDodgeChance = (
  defenderEvasion: number,
  attackerAccuracy: number
): number => {
  const dodgeChance = defenderEvasion / (defenderEvasion + attackerAccuracy);

  return dodgeChance;
};

export const getEscapeChance = (
  characterMovementSpeed: number,
  opponentMovementSpeed: number
): number => {
  const escapeChance =
    characterMovementSpeed / (characterMovementSpeed + opponentMovementSpeed);

  return escapeChance;
};

export const doesDodgeHappen = (
  attackerAccuracy: number,
  defenderEvasion: number
): boolean => {
  const diceRoll = rollDice();

  const dodgeChance = getDodgeChance(defenderEvasion, attackerAccuracy);

  const isDodge = diceRoll <= dodgeChance;

  return isDodge;
};

export const doesEscapeHappen = (
  characterMovementSpeed: number,
  opponentMovementSpeed: number
): boolean => {
  const diceRoll = rollDice();

  const escapeChance = getEscapeChance(
    characterMovementSpeed,
    opponentMovementSpeed
  );

  const isEscape = diceRoll <= escapeChance;

  return isEscape;
};

export const getTotalWeakenedIntegrity = (
  weakenedBaseStats: CharacterBaseStats
): number => {
  let totalWeakenedIntegrity = 0;
  for (const key in weakenedBaseStats) {
    const statSlug = key as keyof CharacterBaseStats;
    const weakenedValue = weakenedBaseStats[statSlug];
    totalWeakenedIntegrity += weakenedValue;
  }

  return totalWeakenedIntegrity;
};

// FIGHT RESULTS

export const getExpGained = (
  level: number,
  mobLevel: number,
  ratingMultiplier: number
): number => {
  const propertyInfo = getPropertyData("experience");

  const levelDiff = level - mobLevel;

  // Get exp multiplier based on difference between player and mob
  // Use different multiplier depending on if mob is higher level or lower level
  const mobMultiplier =
    levelDiff > 0 ? LOWER_MOB_EXP_MULTIPLIER : HIGHER_MOB_EXP_MULTIPLIER;
  const levelExpMultiplier = Math.max(0, 1 - levelDiff * mobMultiplier);
  const baseExpGained = BASE_MOB_EXP ** levelExpMultiplier;

  const totalExpGained = Math.min(
    MAX_MOB_EXP,
    baseExpGained * ratingMultiplier
  );

  return propertyInfo.rounder(totalExpGained);
};

export const getCreditsGained = (
  mobLevel: number,
  multiplier: number
): number => {
  const baseCredits = mobLevel * MOB_CREDITS_PER_LEVEL;
  const creditsGained = roundWhole(baseCredits * multiplier);

  return creditsGained;
};

export const getStatPointsGained = (
  newLevel: number,
  levelsGained: number
): number => {
  const oldLevel = newLevel - levelsGained;
  let statPointsGained = 0;

  for (let i = oldLevel + 1; i <= newLevel; i++) {
    const statsPerLevel = Math.ceil(i / 10);
    statPointsGained += statsPerLevel;
  }

  return statPointsGained;
};

export const getMobDrops = (
  drops: MobDrops,
  battleRating: BattleRatings
): MobDropModel[] => {
  // Get drops based on rating, should get all lower rating drops as well
  const gainedDrops: MobDropModel[] = [];

  for (const key in BATTLE_RATINGS) {
    const ratingKey = key as BattleRatings;
    const ratingData = BATTLE_RATINGS[ratingKey] as BattleRatingModel;

    const { value: fightRatingValue } = getBattleRatingData(battleRating);

    if (fightRatingValue >= ratingData.value) {
      // Compare value to actually battle rating value to grab each drop?
      if (drops[ratingKey]) {
        const drop = drops[ratingKey] as MobDropModel;
        gainedDrops.push(drop);
      }
    }
  }

  return gainedDrops;
};

// BATTLE RATINGS

export const calculateBattleRating = (
  damageDealt: DamageModel[],
  damageTaken: DamageModel[]
): BattleRatings => {
  const damageRatio: number = calculateDamageRatio(damageDealt, damageTaken);

  let battleRating: BattleRatings = "bronze";

  for (const key in BATTLE_RATINGS) {
    const ratingKey = key as BattleRatings;
    const ratingData = BATTLE_RATINGS[ratingKey] as BattleRatingModel;
    if (damageRatio > ratingData.damageRatio) {
      battleRating = ratingKey;
      break;
    }
  }

  return battleRating;
};

export const calculateDamageRatio = (
  damageDealt: DamageModel[],
  damageTaken: DamageModel[]
) => {
  const damageDealtSum = damageDealt.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damage,
    0
  );
  const damageTakenSum = damageTaken.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damage,
    0
  );

  if (damageDealtSum === 0 && damageTakenSum === 0) {
    return 1;
  } else if (damageDealtSum > 0 && damageTakenSum === 0) {
    return 999;
  } else if (damageDealtSum === 0 && damageTakenSum > 0) {
    return 0;
  } else {
    return damageDealtSum / damageTakenSum;
  }
};

export const formatDamageRatio = (
  damageDealt: DamageModel[],
  damageTaken: DamageModel[]
) => {
  const damageDealtSum = damageDealt.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damage,
    0
  );
  const damageTakenSum = damageTaken.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damage,
    0
  );

  if (damageDealtSum === 0 && damageTakenSum === 0) {
    return "0:0";
  } else if (damageDealtSum > 0 && damageTakenSum === 0) {
    return "1:0";
  } else if (damageDealtSum === 0 && damageTakenSum > 0) {
    return "0:1";
  } else {
    return `${roundOneDecimal(damageDealtSum / damageTakenSum)}:1`;
  }
};

export const calculateDamagePercentage = (
  damageDealt: DamageModel[],
  damageTaken: DamageModel[]
) => {
  const DEFAULT_PERCENTAGE = 0.5;

  const damageDealtSum = damageDealt.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damage,
    0
  );
  const damageTakenSum = damageTaken.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damage,
    0
  );
  const totalDamageSum = damageDealtSum + damageTakenSum;

  const damageRatio: number =
    totalDamageSum > 0
      ? damageDealtSum / (damageDealtSum + damageTakenSum)
      : DEFAULT_PERCENTAGE;

  return damageRatio;
};

export const getPlatinumBattleRatingCount = (
  battleRatings: BattleRatingsData
) => {
  const platinumCount = Object.values(battleRatings).reduce(
    (count: number, rating: BattleRatings) => {
      return rating === "platinum" ? count + 1 : count;
    },
    0
  );

  return platinumCount;
};
