import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { glowAnimation } from "components/styles/animations";
import { getBuildingData } from "libs/stats";
import { enterBuilding } from "redux/actions";

interface BuildingProps {
  slug: string;
}

interface BuildingWrapperProps {
  position: number;
}

const BuildingWrapper = styled.div<BuildingWrapperProps>`
  position: absolute;
  height: 100%;
  z-index: 2;
  left: ${(props) => props.position * 0.2}%;
  animation: ${glowAnimation} 2s ease-in-out infinite;
`;

const BuildingImage = styled.img`
  height: 100%;
  cursor: pointer;
  transition: filter 100ms ease-out;

  &:hover {
    filter: drop-shadow(8px 16px 20px #ffffff40);
  }
`;

export default function Building({ slug }: BuildingProps) {
  const dispatch = useDispatch();
  const building = getBuildingData(slug);

  return (
    <BuildingWrapper position={building.position}>
      <BuildingImage
        src={building.exteriorImage}
        onClick={() =>
          dispatch(
            enterBuilding({ building: slug, screen: null, dialog: null })
          )
        }
      />
    </BuildingWrapper>
  );
}
