import cloneDeep from "lodash.clonedeep";
import merge from "lodash.merge";
import { combineReducers } from "redux";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PLAYLISTS } from "data/music";
import {
  AreaPayload,
  AuthOverlayScreens,
  BuildingPayload,
  CharacterBaseStats,
  CharacterData,
  CharacterState,
  CombatLogPayload,
  DamagePayload,
  DataScreenTypes,
  FightState,
  FightStatuses,
  GameState,
  InstallPayload,
  ItemPayload,
  LogMessage,
  MainMenuScreens,
  NewCharacterScreens,
  OnlineUser,
  OpponentPayload,
  PartTypes,
  PilotTypes,
  PlaylistModel,
  RatingPayload,
  RechargePayload,
  RestorePayload,
  ResultsPayload,
  ShipTypes,
  SkillValuePayload,
  WeakenPayload,
} from "types";
import { roundWhole } from "utils/formatters";

// Character State

export const initialCharacterState: CharacterState = {
  userId: null,
  userName: null,
  data: {
    ship: "hyperion",
    pilot: "weaponsSpecialist",
    level: 1,
    baseStatPoints: 0,
    enhancedBaseStats: {
      firepower: 0,
      resilience: 0,
      speed: 0,
      precision: 0,
      energy: 0,
    },
    trainedSkills: [],
    damage: [],
    weakenedBaseStats: {
      firepower: 0,
      resilience: 0,
      speed: 0,
      precision: 0,
      energy: 0,
    },
    skillsRecharge: {},
    skillsValues: {},
    battleRatings: {},
    health: 0,
    levelExp: 0,
    credits: 0,
    location: {
      planet: "bishop",
      area: "launchCheck",
      building: null,
      buildingScreen: null,
      buildingDialog: null,
      position: 0,
      isNearBoundary: false,
    },
    inventory: {
      supplies: [
        { slug: "minor_repair_kit", quantity: 10 },
        { slug: "minor_restore_module", quantity: 10 },
      ],
      upgrades: [],
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    ui: {
      animations: [],
      isMoving: false,
      lastMove: "right",
      isMovementDisabled: false,
      isInsideShip: false,
      dataScreen: "vitals",
      playerDetails: null,
    },
    settings: {
      isMusicEnabled: false,
    },
  },
};

const characterSlice = createSlice({
  name: "character",
  initialState: initialCharacterState as CharacterState,
  reducers: {
    setUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload;
    },
    setUserName(state, action: PayloadAction<string>) {
      state.userName = action.payload;
    },
    setShipClass(state, action: PayloadAction<ShipTypes>) {
      state.data.ship = action.payload;
    },
    setPilotProfession(state, action: PayloadAction<PilotTypes>) {
      state.data.pilot = action.payload;
    },
    setInitialCharacterState(state, action: PayloadAction<CharacterData>) {
      state.data = merge(state.data, action.payload);
    },
    clearCharacterState(state) {
      state.userId = null;
      state.userName = null;
      state.data = initialCharacterState.data;
    },
    moveLeft(state, action: PayloadAction<number>) {
      state.data.location.position -= action.payload;
      state.data.ui.isMoving = true;
      state.data.ui.lastMove = "left";
    },
    moveRight(state, action: PayloadAction<number>) {
      state.data.location.position += action.payload;
      state.data.ui.isMoving = true;
      state.data.ui.lastMove = "right";
    },
    turnShip(state, action: PayloadAction<string>) {
      state.data.ui.lastMove = action.payload;
    },
    stopMove(state) {
      state.data.ui.isMoving = false;
    },
    disableMovement(state) {
      state.data.ui.isMovementDisabled = true;
    },
    enableMovement(state) {
      state.data.ui.isMovementDisabled = false;
    },
    moveToPosition(state, action: PayloadAction<number>) {
      state.data.location.position = action.payload;
    },
    showInsideShip(state) {
      state.data.ui.isInsideShip = true;
    },
    hideInsideShip(state) {
      state.data.ui.isInsideShip = false;
    },
    switchDataScreen(state, action: PayloadAction<DataScreenTypes>) {
      state.data.ui.dataScreen = action.payload;
    },
    showPlayerDetails(state, action: PayloadAction<string | null>) {
      state.data.ui.playerDetails = action.payload;
    },
    showInsideBuilding(state, action: PayloadAction<BuildingPayload>) {
      const { building, screen, dialog } = action.payload;
      state.data.location.building = building;
      if (screen) {
        state.data.location.buildingScreen = screen;
      }
      if (dialog) {
        state.data.location.buildingDialog = dialog;
      }
    },
    hideInsideBuilding(state) {
      state.data.location.building = null;
      state.data.location.buildingDialog = null;
    },
    switchBuildingScreen(state, action: PayloadAction<string>) {
      state.data.location.buildingScreen = action.payload;
      state.data.location.buildingDialog = null;
    },
    setNearBoundary(state, action: PayloadAction<boolean>) {
      state.data.location.isNearBoundary = action.payload;
    },
    enterArea(state, action: PayloadAction<AreaPayload>) {
      const { area, position, direction } = action.payload;

      state.data.location.area = area;
      state.data.location.position = position;
      state.data.ui.lastMove = direction;
    },
    animateAction(state, action: PayloadAction<string>) {
      state.data.ui.animations.push(action.payload);
    },
    endAnimation(state, action: PayloadAction<string>) {
      const animationIndex = state.data.ui.animations.findIndex((anim) => {
        return anim === action.payload;
      });
      state.data.ui.animations.splice(animationIndex, 1);
    },
    resetAnimations(state) {
      state.data.ui.animations = [];
    },
    takeDamage(state, action: PayloadAction<DamagePayload>) {
      const {
        damage,
        weakenParts,
        baseStatWeakened,
        totalBaseStats,
        damageReduced,
      } = action.payload;

      // Show damage
      state.data.damage.push({
        damage,
        baseStatWeakened: baseStatWeakened || null,
        damageReduced,
      });

      // Reduce health
      state.data.health = Math.max(0, roundWhole(state.data.health - damage));

      // Prevent overall weakened base stats from being higher than total base stats
      if (baseStatWeakened) {
        state.data.weakenedBaseStats[baseStatWeakened] = Math.min(
          totalBaseStats[baseStatWeakened],
          state.data.weakenedBaseStats[baseStatWeakened] + weakenParts
        );
      }
    },
    resetDamage(state) {
      state.data.damage = [];
    },
    gainHealth(state, action: PayloadAction<number>) {
      state.data.health += action.payload;
    },
    setHealth(state, action: PayloadAction<number>) {
      state.data.health = action.payload;
    },
    weakenPart(state, action: PayloadAction<WeakenPayload>) {
      const { baseStatWeakened, weakenPartAmount, totalBaseStats } =
        action.payload;

      // Prevent overall weakened base stats from being higher than total base stats
      state.data.weakenedBaseStats[baseStatWeakened] = Math.min(
        totalBaseStats[baseStatWeakened],
        state.data.weakenedBaseStats[baseStatWeakened] + weakenPartAmount
      );
    },
    restoreAllParts(state) {
      state.data.weakenedBaseStats = {
        firepower: 0,
        resilience: 0,
        speed: 0,
        precision: 0,
        energy: 0,
      };
    },
    restorePart(state, action: PayloadAction<RestorePayload>) {
      const { baseStatRestored, restorePartAmount } = action.payload;

      const statType: keyof CharacterBaseStats = baseStatRestored;
      state.data.weakenedBaseStats[statType] = Math.max(
        state.data.weakenedBaseStats[statType] - restorePartAmount,
        0
      );
    },
    setMobBattleRating(state, action: PayloadAction<RatingPayload>) {
      const { mob, rating } = action.payload;

      state.data.battleRatings[mob] = rating;
    },
    setLevelExp(state, action: PayloadAction<number>) {
      state.data.levelExp = action.payload;
    },
    levelUp(state, action: PayloadAction<number>) {
      state.data.level += action.payload;
    },
    gainStatPoints(state, action: PayloadAction<number>) {
      state.data.baseStatPoints += action.payload;
    },
    loseStatPoints(state, action: PayloadAction<number>) {
      state.data.baseStatPoints -= action.payload;
    },
    gainCredits(state, action: PayloadAction<number>) {
      state.data.credits += action.payload;
    },
    loseCredits(state, action: PayloadAction<number>) {
      state.data.credits -= action.payload;
    },
    getSupply(state, action: PayloadAction<ItemPayload>) {
      const existingSupplyIndex = state.data.inventory.supplies.findIndex(
        (supply) => {
          return supply.slug === action.payload.slug;
        }
      );
      if (existingSupplyIndex > -1) {
        state.data.inventory.supplies[existingSupplyIndex].quantity +=
          action.payload.quantity;
      } else {
        state.data.inventory.supplies.push(action.payload);
      }
    },
    removeSupply(state, action: PayloadAction<ItemPayload>) {
      const existingSupplyIndex = state.data.inventory.supplies.findIndex(
        (supply) => {
          return supply.slug === action.payload.slug;
        }
      );
      if (existingSupplyIndex > -1) {
        const quantity =
          state.data.inventory.supplies[existingSupplyIndex].quantity;
        if (quantity > action.payload.quantity) {
          state.data.inventory.supplies[existingSupplyIndex].quantity -=
            action.payload.quantity;
        } else {
          state.data.inventory.supplies.splice(existingSupplyIndex, 1);
        }
      }
    },
    getUpgrade(state, action: PayloadAction<ItemPayload>) {
      const existingUpgradeIndex = state.data.inventory.upgrades.findIndex(
        (upgrade) => {
          return upgrade.slug === action.payload.slug;
        }
      );
      if (existingUpgradeIndex > -1) {
        state.data.inventory.upgrades[existingUpgradeIndex].quantity +=
          action.payload.quantity;
      } else {
        state.data.inventory.upgrades.push(action.payload);
      }
    },
    removeUpgrade(state, action: PayloadAction<ItemPayload>) {
      const existingUpgradeIndex = state.data.inventory.upgrades.findIndex(
        (upgrade) => {
          return upgrade.slug === action.payload.slug;
        }
      );
      if (existingUpgradeIndex > -1) {
        const quantity =
          state.data.inventory.upgrades[existingUpgradeIndex].quantity;
        if (quantity > action.payload.quantity) {
          state.data.inventory.upgrades[existingUpgradeIndex].quantity -=
            action.payload.quantity;
        } else {
          state.data.inventory.upgrades.splice(existingUpgradeIndex, 1);
        }
      }
    },
    enhanceStat(state, action: PayloadAction<keyof CharacterBaseStats>) {
      state.data.enhancedBaseStats[action.payload] += 1;
    },
    resetStats(state) {
      state.data.enhancedBaseStats = {
        firepower: 0,
        resilience: 0,
        speed: 0,
        precision: 0,
        energy: 0,
      };
    },
    trainSkill(state, action: PayloadAction<string>) {
      state.data.trainedSkills.push(action.payload);
    },
    resetSkills(state) {
      state.data.trainedSkills = [];
    },
    setInstalledUpgrade(state, action: PayloadAction<InstallPayload>) {
      state.data.installedUpgrades[action.payload.part] = action.payload.slug;
    },
    setUninstalledUpgrade(state, action: PayloadAction<PartTypes>) {
      state.data.installedUpgrades[action.payload] = null;
    },
    setSkillRecharge(state, action: PayloadAction<RechargePayload>) {
      state.data.skillsRecharge[action.payload.slug] = action.payload.turns;
    },
    rechargeSkillsTurn(state) {
      const skills = state.data.skillsRecharge;
      Object.keys(skills).forEach((key) => {
        const skillSlug = key as string;
        if (skills.hasOwnProperty(skillSlug)) {
          if (state.data.skillsRecharge[skillSlug] > 0) {
            state.data.skillsRecharge[skillSlug] -= 1;
          }
        }
      });
    },
    resetSkillsRecharge(state) {
      state.data.skillsRecharge = {};
    },
    setSkillValue(state, action: PayloadAction<SkillValuePayload>) {
      state.data.skillsValues[action.payload.slug] = action.payload.value;
    },
    resetSkillsValues(state) {
      state.data.skillsValues = {};
    },
    setMusicEnabled(state) {
      state.data.settings.isMusicEnabled = true;
    },
    setMusicDisabled(state) {
      state.data.settings.isMusicEnabled = false;
    },
  },
});

// Game State - not saved in Firebase

export const initialGameState: GameState = {
  isInitialDataLoaded: false,
  isGameModeActive: false,
  isNewUser: false,
  mainMenuScreen: "title",
  newCharacterScreen: "shipSelect",
  isUserAccountLinked: false,
  isAuthOverlayOpen: false,
  authOverlayScreen: "login",
  nameSelectMessage: null,
  messages: [],
  onlineUsers: [],
  isHideWorld: false,
  logMessages: [],
  isMusicPlaying: false,
  currentTrack: "lightVoyagersTheme",
  trackChanges: 0,
  currentPlaylist: PLAYLISTS.titleScreen,
};

const gameSlice = createSlice({
  name: "game",
  initialState: initialGameState as GameState,
  reducers: {
    setInitialDataLoaded(state) {
      state.isInitialDataLoaded = true;
    },
    setGameModeActive(state) {
      state.isGameModeActive = true;
    },
    setGameModeInactive(state) {
      state.isGameModeActive = false;
    },
    setNewUser(state, action: PayloadAction<boolean>) {
      state.isNewUser = action.payload;
    },
    setMainMenuScreen(state, action: PayloadAction<MainMenuScreens>) {
      state.mainMenuScreen = action.payload;
    },
    setNewCharacterScreen(state, action: PayloadAction<NewCharacterScreens>) {
      state.newCharacterScreen = action.payload;
    },
    setUserAccountLinked(state) {
      state.isUserAccountLinked = true;
    },
    setUserAccountUnlinked(state) {
      state.isUserAccountLinked = false;
    },
    showNameSelectMessage(state, action: PayloadAction<string>) {
      state.nameSelectMessage = action.payload;
    },
    showMessage(state, action: PayloadAction<string>) {
      state.messages.push(action.payload);
    },
    setOnlineUsers(state, action: PayloadAction<OnlineUser[]>) {
      const users = action.payload;

      const formattedUsers = users.map((user: OnlineUser) => {
        const initialCharacterData = cloneDeep(initialCharacterState.data);
        const mergedData = merge(initialCharacterData, user.characterData);
        return {
          ...user,
          characterData: mergedData,
        };
      });

      state.onlineUsers = formattedUsers;
    },
    hideWorld(state) {
      state.isHideWorld = true;
    },
    showWorld(state) {
      state.isHideWorld = false;
    },
    showAuthOverlay(state) {
      state.isAuthOverlayOpen = true;
    },
    hideAuthOverlay(state) {
      state.isAuthOverlayOpen = false;
    },
    setAuthOverlayScreen(state, action: PayloadAction<AuthOverlayScreens>) {
      state.authOverlayScreen = action.payload;
    },
    setLogMessages(state, action: PayloadAction<LogMessage[]>) {
      state.logMessages = action.payload;
    },
    startMusicPlayer(state) {
      state.isMusicPlaying = true;
    },
    stopMusicPlayer(state) {
      state.isMusicPlaying = false;
    },
    setCurrentTrack(state, action: PayloadAction<string>) {
      state.currentTrack = action.payload;
    },
    setTrackChange(state) {
      state.trackChanges += 1;
    },
    setCurrentPlaylist(state, action: PayloadAction<PlaylistModel>) {
      state.currentPlaylist = action.payload;
    },
  },
});

// Fight State

export const initialFightState: FightState = {
  status: "notFighting",
  characterTurnPriority: 0,
  opponentTurnPriority: 0,
  areActionsEnabled: false,
  results: {
    battleRating: "bronze",
    experience: 0,
    credits: 0,
    drops: [],
    isLevelUp: false,
    statPoints: 0,
  },
  opponent: {
    slug: "conway",
    weakenedBaseStats: {
      firepower: 0,
      resilience: 0,
      speed: 0,
      precision: 0,
      energy: 0,
    },
    skillsRecharge: {},
    skillsValues: {},
    health: 0,
    damage: [],
    animations: [],
  },
  combatLogs: [],
  isCombatLogOpen: true,
};

const fightSlice = createSlice({
  name: "fight",
  initialState: initialFightState as FightState,
  reducers: {
    setInitialFightState(state, action: PayloadAction<FightState>) {
      state = merge(state, action.payload);
    },
    clearFightState(state) {
      state = initialFightState;
    },
    setFightStatus(state, action: PayloadAction<FightStatuses>) {
      state.status = action.payload;
    },
    setupOpponent(state, action: PayloadAction<OpponentPayload>) {
      const { slug, health } = action.payload;
      state.opponent.slug = slug;
      state.opponent.health = health;
    },
    addCharacterTurnPriority(state, action: PayloadAction<number>) {
      state.characterTurnPriority += action.payload;
    },
    addOpponentTurnPriority(state, action: PayloadAction<number>) {
      state.opponentTurnPriority += action.payload;
    },
    resetTurnPriorities(state) {
      state.characterTurnPriority = 0;
      state.opponentTurnPriority = 0;
    },
    enableFightActions(state) {
      state.areActionsEnabled = true;
    },
    disableFightActions(state) {
      state.areActionsEnabled = false;
    },
    attackOpponent(state, action: PayloadAction<DamagePayload>) {
      const {
        damage,
        weakenParts,
        baseStatWeakened,
        totalBaseStats,
        damageReduced,
      } = action.payload;

      // Show damage
      state.opponent.damage.push({
        damage,
        baseStatWeakened: baseStatWeakened || null,
        damageReduced,
      });

      // Reduce health
      state.opponent.health = Math.max(0, state.opponent.health - damage);

      // Prevent overall weakened base stats from being higher than total base stats
      if (baseStatWeakened) {
        state.opponent.weakenedBaseStats[baseStatWeakened] = Math.min(
          totalBaseStats[baseStatWeakened],
          state.opponent.weakenedBaseStats[baseStatWeakened] + weakenParts
        );
      }
    },
    setOpponentHealth(state, action: PayloadAction<number>) {
      state.opponent.health = action.payload;
    },
    healOpponent(state, action: PayloadAction<number>) {
      state.opponent.health += action.payload;
    },
    restoreOpponentPart(state, action: PayloadAction<RestorePayload>) {
      const { baseStatRestored, restorePartAmount } = action.payload;

      const statType: keyof CharacterBaseStats = baseStatRestored;
      state.opponent.weakenedBaseStats[statType] = Math.max(
        state.opponent.weakenedBaseStats[statType] - restorePartAmount,
        0
      );
    },
    weakenOpponentPart(state, action: PayloadAction<WeakenPayload>) {
      const { baseStatWeakened, weakenPartAmount, totalBaseStats } =
        action.payload;

      // Prevent overall weakened base stats from being higher than total base stats
      state.opponent.weakenedBaseStats[baseStatWeakened] = Math.min(
        totalBaseStats[baseStatWeakened],
        state.opponent.weakenedBaseStats[baseStatWeakened] + weakenPartAmount
      );
    },
    setOpponentSkillRecharge(state, action: PayloadAction<RechargePayload>) {
      state.opponent.skillsRecharge[action.payload.slug] = action.payload.turns;
    },
    rechargeOpponentSkillsTurn(state) {
      const skills = state.opponent.skillsRecharge;
      Object.keys(skills).forEach((key) => {
        const skillSlug = key as string;
        if (skills.hasOwnProperty(skillSlug)) {
          if (state.opponent.skillsRecharge[skillSlug] > 0) {
            state.opponent.skillsRecharge[skillSlug] -= 1;
          }
        }
      });
    },
    resetOpponentSkillsRecharge(state) {
      state.opponent.skillsRecharge = {};
    },
    setOpponentSkillValue(state, action: PayloadAction<SkillValuePayload>) {
      state.opponent.skillsValues[action.payload.slug] = action.payload.value;
    },
    resetOpponentSkillsValues(state) {
      state.opponent.skillsValues = {};
    },
    resetOpponentDamage(state) {
      state.opponent.damage = [];
    },
    resetOpponentParts(state) {
      state.opponent.weakenedBaseStats = {
        firepower: 0,
        resilience: 0,
        speed: 0,
        precision: 0,
        energy: 0,
      };
    },
    animateOpponentAction(state, action: PayloadAction<string>) {
      state.opponent.animations.push(action.payload);
    },
    endOpponentAnimation(state, action: PayloadAction<string>) {
      const animationIndex = state.opponent.animations.findIndex((anim) => {
        return anim === action.payload;
      });
      state.opponent.animations.splice(animationIndex, 1);
    },
    resetOpponentAnimations(state) {
      state.opponent.animations = [];
    },
    setFightResults(state, action: PayloadAction<ResultsPayload>) {
      state.results = { ...state.results, ...action.payload };
    },
    resetFightResults(state) {
      state.results = {
        battleRating: "bronze",
        experience: 0,
        credits: 0,
        drops: [],
        isLevelUp: false,
        statPoints: 0,
      };
    },
    addCombatLog(state, action: PayloadAction<CombatLogPayload>) {
      state.combatLogs.push(action.payload);
    },
    resetCombatLogs(state) {
      state.combatLogs = [];
    },
    openCombatLog(state) {
      state.isCombatLogOpen = true;
    },
    closeCombatLog(state) {
      state.isCombatLogOpen = false;
    },
  },
});

export const characterReducer = characterSlice.reducer;
export const gameReducer = gameSlice.reducer;
export const fightReducer = fightSlice.reducer;
export const characterActions = characterSlice.actions;
export const gameActions = gameSlice.actions;
export const fightActions = fightSlice.actions;

export default combineReducers({
  character: characterReducer,
  game: gameReducer,
  fight: fightReducer,
});
