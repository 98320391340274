import reaserAvatarImage from "assets/npc-reaser-avatar.svg";
import Button from "components/base/Button";
import ShipCard from "components/base/ShipCard";
import { floatAnimation } from "components/styles/animations";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCharacter,
  setNewCharacterScreen,
  validateCharacterName,
} from "redux/actions";
import { getGameState, getShipUpgrades } from "redux/selectors";
import styled from "styled-components";
import { NewCharacterScreens, PilotTypes, ShipTypes } from "types";
import { useImmer } from "use-immer";
import { getAllPilotsData, getAllShipsData, getPilotData } from "libs/stats";
import AvatarShip from "./AvatarShip";
import PilotCard from "./PilotCard";

interface NewCharacterProps {
  onBack: () => void;
}

interface NewCharacterState {
  ship?: ShipTypes;
  pilot?: PilotTypes;
  userName?: string;
}

const NewCharacterWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #00000099;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  padding: 20px 0 40px;
  overflow-y: scroll;
  box-sizing: border-box;
`;

const CommanderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`;

const Commander = styled.div`
  display: flex;
  align-items: end;
  max-width: 75%;
`;

const CommanderImage = styled.img`
  height: 140px;
  display: block;
  margin-right: -40px;
  z-index: 1;
`;

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const CommanderName = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 40px;
`;

const Dialog = styled.div`
  background-color: #0e404299;
  padding: 20px;
  border-radius: 10px;
  color: #ddd;
  text-align: left;
  padding-left: 40px;
`;

const SelectWrapper = styled.div``;

// Ship Select

const ShipSelect = styled.div``;

const Ships = styled.div`
  padding: 0 2%;
  display: flex;
  align-items: stretch;
  gap: 1%;
`;

// Pilot Select

const PilotSelect = styled.div``;

const Pilots = styled.div`
  padding: 0 2%;
  display: flex;
  align-items: stretch;
  gap: 1%;
`;

// Name Select

const NameSelect = styled.div``;

const NameInput = styled.input`
  background-color: #ffffff25;
  border: 1px white solid;
  padding: 15px;
  font-size: 18px;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  color: white;
  text-align: center;
  min-width: 300px;
  &::placeholder {
    color: white;
    opacity: 0.75;
  }
`;

const NameSelectMessage = styled.div`
  margin-top: 15px;
  font-size: 16px;
  color: #ffffff;
  padding: 10px 15px;
  background-color: #af222240;
`;

// Save Auth

const SaveCharacter = styled.div``;

const CharacterSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const SelectedImages = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const SelectedShipWrapper = styled.div`
  width: 150px;
  height: 150px;
  background-color: #ffffff10;
  border: 1px #ffffff20 solid;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const SelectedShip = styled.div`
  animation: ${floatAnimation} 3s ease-in-out infinite;
  position: relative;
  width: 100%;
  height: 100%;
`;

const SelectedPilot = styled.div`
  width: 150px;
  height: 150px;
  background-color: #ffffff10;
  border: 1px #ffffff20 solid;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
`;

const SelectedPilotImage = styled.img`
  height: 100%;
`;

const SelectedName = styled.div`
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  border-radius: 10px;
  background-color: #ffffff10;
  border: 1px #ffffff20 solid;
`;

const SaveOption = styled.div``;

// Shared Navigation

const NavigationActions = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 20px;
  justify-content: center;
`;

const SCREENS = {
  shipSelect: {
    dialog:
      "Congratulations on graduating from Bishop Pilot Academy and becoming a full-fledged starship pilot! You have been the shining star of your entire cohort and I've always known you were destined for greatness. ...But remind me, which starship do you fly again?",
  },
  pilotSelect: {
    dialog:
      "And forgive me, which pilot discipline did you specialize in again?",
  },
  nameSelect: {
    dialog:
      "Oh right, of course. And I'm so embarrassed to have to ask, but... who are you again?",
  },
  saveCharacter: {
    dialog:
      "Yes, yes, I obviously knew all of that. I was just kidding around, a little graduation humor. Now get out of here and go on some amazing adventures out there in the vast galaxies... champ.",
  },
};

export default function NewCharacter({ onBack }: NewCharacterProps) {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const { newCharacterScreen, nameSelectMessage } = useSelector(getGameState);

  const [state, setState] = useImmer<NewCharacterState>({});

  const allShips = getAllShipsData();
  const allPilots = getAllPilotsData();

  const getSelectedShip = (ship?: ShipTypes) => {
    if (ship) {
      const defaultShipData = {
        ship: ship,
        shipUpgrades: getShipUpgrades(),
        ui: { animation: null, isMoving: false },
      };

      return defaultShipData;
    }
    return;
  };

  const getSelectedPilot = (pilot?: PilotTypes) => {
    if (pilot) {
      const { images } = getPilotData(pilot);

      return images.avatar;
    }
    return;
  };

  const goToScreen = (screen: NewCharacterScreens) => {
    dispatch(setNewCharacterScreen(screen));
  };

  const handleClickShip = (ship: ShipTypes) => {
    setState((draft: NewCharacterState) => {
      draft.ship = ship;
    });
  };

  const handleClickPilot = (pilot: PilotTypes) => {
    setState((draft: NewCharacterState) => {
      draft.pilot = pilot;
    });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((draft: NewCharacterState) => {
      draft.userName = event.target.value;
    });
  };

  const handleNameKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      validateUserName();
    }
  };

  const validateUserName = () => {
    if (!!state.userName) {
      dispatch(validateCharacterName(state.userName));
    }
  };

  const saveNewCharacter = () => {
    if (!!state.ship && !!state.pilot && !!state.userName) {
      // Save new character to Firebase
      dispatch(
        createCharacter({
          ship: state.ship,
          pilot: state.pilot,
          userName: state.userName,
        })
      );
    }
  };

  return (
    <NewCharacterWrapper>
      <CommanderWrapper>
        <Commander>
          <CommanderImage alt="commander image" src={reaserAvatarImage} />
          <DialogWrapper>
            <CommanderName>Pilot Commander Reaser</CommanderName>
            <Dialog>{SCREENS[newCharacterScreen].dialog}</Dialog>
          </DialogWrapper>
        </Commander>
      </CommanderWrapper>
      <SelectWrapper>
        {newCharacterScreen === "shipSelect" && (
          <ShipSelect>
            <Ships>
              {Object.entries(allShips).map(([key, value]) => {
                const shipSlug = key as ShipTypes;

                return (
                  <ShipCard
                    shipSlug={shipSlug}
                    isSelected={state.ship === shipSlug}
                    onClick={(shipSlug: ShipTypes) => handleClickShip(shipSlug)}
                  />
                );
              })}
            </Ships>
            <NavigationActions>
              <Button $style="secondary" $size="large" onClick={onBack}>
                Back
              </Button>
              <Button
                $style="normal"
                $size="large"
                $disabled={!state.ship}
                onClick={() => goToScreen("pilotSelect")}
              >
                Select
              </Button>
            </NavigationActions>
          </ShipSelect>
        )}
        {newCharacterScreen === "pilotSelect" && (
          <PilotSelect>
            <Pilots>
              {Object.entries(allPilots).map(([key, value]) => {
                const pilotSlug = key as PilotTypes;

                return (
                  <PilotCard
                    pilotSlug={pilotSlug}
                    isSelected={state.pilot === pilotSlug}
                    onClick={(pilotSlug: PilotTypes) =>
                      handleClickPilot(pilotSlug)
                    }
                  />
                );
              })}
            </Pilots>
            <NavigationActions>
              <Button
                $style="secondary"
                $size="large"
                onClick={() => goToScreen("shipSelect")}
              >
                Back
              </Button>
              <Button
                $style="normal"
                $size="large"
                $disabled={!state.pilot}
                onClick={() => goToScreen("nameSelect")}
              >
                Select
              </Button>
            </NavigationActions>
          </PilotSelect>
        )}
        {newCharacterScreen === "nameSelect" && (
          <NameSelect>
            <NameInput
              ref={inputRef}
              placeholder="Enter your character name"
              autoFocus
              value={state.userName}
              onChange={handleNameChange}
              onKeyDown={handleNameKeyDown}
              maxLength={15}
            />
            {!!nameSelectMessage && (
              <NameSelectMessage>{nameSelectMessage}</NameSelectMessage>
            )}
            <NavigationActions>
              <Button
                $style="secondary"
                $size="large"
                onClick={() => goToScreen("pilotSelect")}
              >
                Back
              </Button>
              <Button
                $style="normal"
                $size="large"
                $disabled={!state.userName}
                onClick={validateUserName}
              >
                Select
              </Button>
            </NavigationActions>
          </NameSelect>
        )}
        {newCharacterScreen === "saveCharacter" && (
          <SaveCharacter>
            <CharacterSummary>
              <SelectedImages>
                <SelectedShipWrapper>
                  <SelectedShip>
                    {!!state.ship && (
                      <AvatarShip
                        highlightPart={null}
                        shipData={getSelectedShip(state.ship)}
                      />
                    )}
                  </SelectedShip>
                </SelectedShipWrapper>
                <SelectedPilot>
                  {!!state.pilot && (
                    <SelectedPilotImage src={getSelectedPilot(state.pilot)} />
                  )}
                </SelectedPilot>
              </SelectedImages>
              <SelectedName>{state.userName}</SelectedName>
            </CharacterSummary>
            <SaveOption></SaveOption>
            <NavigationActions>
              <Button
                $style="secondary"
                $size="large"
                onClick={() => goToScreen("nameSelect")}
              >
                Back
              </Button>
              <Button
                $style="normal"
                $size="large"
                $disabled={!state.userName}
                onClick={saveNewCharacter}
              >
                Onward
              </Button>
            </NavigationActions>
          </SaveCharacter>
        )}
      </SelectWrapper>
    </NewCharacterWrapper>
  );
}
