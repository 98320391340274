import { PilotModel, PilotTypes } from "types";

import weaponsSpecialistAvatarImage from "assets/pilot-weapons-specialist.svg";
import weaponsSpecialistInteriorImage from "assets/interior-pilot-weapons-specialist.svg";
import stuntPilotAvatarImage from "assets/pilot-stunt-pilot.svg";
import stuntPilotInteriorImage from "assets/interior-pilot-stunt-pilot.svg";
import mechanicalEngineerAvatarImage from "assets/pilot-mechanical-engineer.svg";
import mechanicalEngineerInteriorImage from "assets/interior-pilot-mechanical-engineer.svg";
import physicistAvatarImage from "assets/pilot-physicist.svg";
import physicistInteriorImage from "assets/interior-pilot-physicist.svg";
import sharpshooterAvatarImage from "assets/pilot-sharpshooter.svg";
import sharpshooterInteriorImage from "assets/interior-pilot-sharpshooter.svg";

export const PILOTS: Record<PilotTypes, PilotModel> = {
  weaponsSpecialist: {
    name: "Weapons Specialist",
    description:
      "Weapons Specialists love working on their ship's weapons (maybe a little too much). Their skills are about setting themselves up to inflict potentially devastating damage.",
    images: {
      avatar: weaponsSpecialistAvatarImage,
      interior: weaponsSpecialistInteriorImage,
    },
    skills: ["shieldBreaker", "scattershot", "powerSurge"],
  },
  stuntPilot: {
    name: "Stunt Pilot",
    description:
      "Stunt Pilots excel in agile and nimble flying, striking opponents many times over before they get a single shot in. Imagine an annoying fly, but one that's shooting lasers at you.",
    images: {
      avatar: stuntPilotAvatarImage,
      interior: stuntPilotInteriorImage,
    },
    skills: ["rapidFire", "phantomStrike", "hyperShift"],
  },
  mechanicalEngineer: {
    name: "Mechanical Engineer",
    description:
      "Mechanical Engineers are skilled in repairing their starships and restoring their shields while in battle. And they just laugh while opponent's puny lasers get deflected away. Rude.",
    images: {
      avatar: mechanicalEngineerAvatarImage,
      interior: mechanicalEngineerInteriorImage,
    },
    skills: ["emergencyRepairs", "shieldRestore", "shieldStorm"],
  },
  physicist: {
    name: "Physicist",
    description:
      "Physicists are real nerds who are obsessed with antimatter energy. This means they have many abilities that let them use antimatter to do all sorts of painful things to opponent ships.",
    images: {
      avatar: physicistAvatarImage,
      interior: physicistInteriorImage,
    },
    skills: [
      "energyBlast",
      "reactorOverload",
      "shieldBypass",
      "systemsDecay",
      "skillsRecharge",
    ],
  },
  sharpshooter: {
    name: "Sharpshooter",
    description:
      "Sharpshooters hit opponents where it hurts the most with pinpoint accuracy. Other pilots think it's just luck, but they'll tell you that it's all skill. Kind of obnoxious, to be honest.",
    images: {
      avatar: sharpshooterAvatarImage,
      interior: sharpshooterInteriorImage,
    },
    skills: ["pressurePoint", "eagleEye", "blastEcho"],
  },
};
