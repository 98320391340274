import { BattleRatingModel, BattleRatings } from "types";
import platinumRatingImage from "assets/rating-platinum.svg";
import goldRatingImage from "assets/rating-gold.svg";
import silverRatingImage from "assets/rating-silver.svg";
import bronzeRatingImage from "assets/rating-bronze.svg";
import { COLORS } from "utils/constants";

const RATIOS: Record<BattleRatings, number> = {
  platinum: 15,
  gold: 5,
  silver: 2,
  bronze: 0,
};

export const BATTLE_RATINGS: Record<BattleRatings, BattleRatingModel> = {
  platinum: {
    value: 4,
    slug: "platinum",
    name: "Platinum",
    description: `You completely destroyed your opponent by achieving ${RATIOS.platinum}:1 damage dealt to damage taken.`,
    image: platinumRatingImage,
    color: COLORS.RATINGS.PLATINUM,
    damageRatio: RATIOS.platinum,
    damagePercentage: RATIOS.platinum / (RATIOS.platinum + 1),
    rewardMultiplier: 2,
  },
  gold: {
    value: 3,
    slug: "gold",
    name: "Gold",
    description: `You severely decimated your opponent by achieving ${RATIOS.gold}:1 damage dealt to damage taken.`,
    image: goldRatingImage,
    color: COLORS.RATINGS.GOLD,
    damageRatio: RATIOS.gold,
    damagePercentage: RATIOS.gold / (RATIOS.gold + 1),
    rewardMultiplier: 1.5,
  },
  silver: {
    value: 2,
    slug: "silver",
    name: "Silver",
    description: `You defeated your opponent quite handily by managing ${RATIOS.silver}:1 damage dealt to damage taken.`,
    image: silverRatingImage,
    color: COLORS.RATINGS.SILVER,
    damageRatio: RATIOS.silver,
    damagePercentage: RATIOS.silver / (RATIOS.silver + 1),
    rewardMultiplier: 1.2,
  },
  bronze: {
    value: 1,
    slug: "bronze",
    name: "Bronze",
    description: "You won the fight, but it wasn't pretty.",
    image: bronzeRatingImage,
    color: COLORS.RATINGS.BRONZE,
    damageRatio: RATIOS.bronze,
    damagePercentage: RATIOS.bronze / (RATIOS.bronze + 1),
    rewardMultiplier: 1,
  },
};
