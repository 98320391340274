import { AREAS, PLANETS } from "data/areas";
import { BASE_STATS, BASE_STATS_INFO, BaseStatInfo } from "data/baseStats";
import { BUILDINGS } from "data/buildings";
import {
  DERIVED_STATS,
  DerivedStatInfo,
  RELATIVE_FIGHT_STATS,
  RelativeFightStatInfo,
  STAT_RANGES,
  StatRangeInfo,
} from "data/derivedStats";
import { MUSIC, PLAYLISTS } from "data/music";
import { NPCS } from "data/npcs";
import { PARTS } from "data/parts";
import { PILOTS } from "data/pilots";
import { PROPERTIES } from "data/properties";
import { SHIPS, ShipsData } from "data/ships";
import { SHOPS } from "data/shops";
import { SKILLS } from "data/skills";
import { SUPPLY_TYPES } from "data/supplies";
import { getDerivedStatSets } from "redux/selectors";
import { FlattenSimpleInterpolation } from "styled-components";
import {
  AreaModel,
  BuildingModel,
  CharacterBaseStats,
  CharacterDerivedStats,
  FightStatuses,
  LocationData,
  MusicTrackModel,
  NPCModel,
  PartModel,
  PartTypes,
  PilotModel,
  PilotTypes,
  PlanetModel,
  PlaylistModel,
  Properties,
  PropertyModel,
  RelativeFightStats,
  ShipModel,
  ShipTypes,
  ShopModel,
  SkillModel,
  StatRanges,
  SupplyTypeModel,
  SupplyTypes,
} from "types";
import { roundOneDecimal } from "../utils/formatters";
import { getMobData } from "./fight";

export const REPAIR_HEALTH_PER_CREDIT = 5;
export const SWITCH_CREDITS_COST_PER_LEVEL = 50;
export const CREDITS_PER_STAT_TOKEN = 20;
export const CREDITS_PER_SKILL_TOKEN = 20;
export const MAX_BASE_STAT_VALUE = 25;
export const MOB_BASE_STATS_MODIFIERS_TOTAL = 10;
export const MOB_BASE_STATS_COSTS_TOTAL = 10;

export const getAvgAttackDamage = (
  minAttackDamage: number,
  maxAttackDamage: number
) => {
  const attackDamageInfo = getDerivedStatData("maxAttackDamage");

  const avgAttackDamage = (minAttackDamage + maxAttackDamage) / 2;

  return attackDamageInfo.rounder(avgAttackDamage);
};

export const getAvgWeakenParts = (
  minWeakenParts: number,
  maxWeakenParts: number
) => {
  const weakenPartsInfo = getDerivedStatData("maxWeakenParts");
  const avgWeakenParts = (minWeakenParts + maxWeakenParts) / 2;

  return weakenPartsInfo.rounder(avgWeakenParts);
};

export const getPilotData = (pilot: PilotTypes): PilotModel => {
  return PILOTS[pilot];
};

export const getAllPilotsData = (): Record<PilotTypes, PilotModel> => {
  return PILOTS;
};

export const getSkillData = (skill: string): SkillModel => {
  return SKILLS[skill];
};

export const getShipData = (ship: ShipTypes): ShipModel => {
  return SHIPS[ship];
};

export const getAllShipsData = (): ShipsData => {
  return SHIPS;
};

export const getNPCData = (npc: string): NPCModel => {
  return NPCS[npc];
};

export const getPlanetData = (planet: string): PlanetModel => {
  return PLANETS[planet];
};

export const getAreaData = (area: string): AreaModel => {
  return AREAS[area];
};

export const getBuildingData = (building: string): BuildingModel => {
  return BUILDINGS[building];
};

export const getMusicData = (track: string): MusicTrackModel => {
  return MUSIC[track];
};

export const getPlaylistData = (track: string): PlaylistModel => {
  return PLAYLISTS[track];
};

export const getShopData = (shop: string): ShopModel => {
  return SHOPS[shop];
};

export const getPartData = (slug: PartTypes): PartModel => {
  return PARTS[slug];
};

export const getSupplyTypeData = (slug: SupplyTypes): SupplyTypeModel => {
  return SUPPLY_TYPES[slug];
};

export const getPropertyData = (slug: Properties): PropertyModel => {
  return PROPERTIES[slug];
};

export const getBaseStatData = (
  baseStat: keyof CharacterBaseStats
): BaseStatInfo => {
  return BASE_STATS_INFO[baseStat];
};

export const getDerivedStatData = (
  stat: keyof CharacterDerivedStats
): DerivedStatInfo => {
  return DERIVED_STATS[stat];
};

export const getStatRangeData = (
  statRange: keyof StatRanges
): StatRangeInfo => {
  return STAT_RANGES[statRange];
};

export const getRelativeFightStatData = (
  fightStat: keyof RelativeFightStats
): RelativeFightStatInfo => {
  return RELATIVE_FIGHT_STATS[fightStat];
};

export const getNextMusicTrack = (
  currentTrack: string,
  currentPlaylist: PlaylistModel
): string => {
  const currentIndex = currentPlaylist.indexOf(currentTrack);

  // If switched to a different playlist, just play first song of new playlist
  // Repeat playlist when ended
  let nextIndex = 0;
  if (currentIndex >= 0 && currentIndex < currentPlaylist.length - 1) {
    nextIndex = currentIndex + 1;
  }

  return currentPlaylist[nextIndex];
};

export const isTrackInPlaylist = (
  currentTrack: string,
  currentPlaylist: PlaylistModel
): boolean => {
  const isInPlaylist = currentPlaylist.includes(currentTrack);

  return isInPlaylist;
};

export const shuffleList = (array: string[]) => {
  return array
    .map((a) => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value);
};

export const getNumberFromDiceRoll = (
  min: number,
  max: number,
  diceRoll: number
): number => {
  return diceRoll * (max - min) + min;
};

export const rollDice = (): number => {
  const diceRoll = Math.random();

  return diceRoll;
};

export const getRandomBaseStat = (): keyof CharacterBaseStats => {
  const baseStats = Object.values(BASE_STATS);

  const randomIndex = Math.floor(Math.random() * baseStats.length);

  const baseStat = baseStats[randomIndex];

  return baseStat;
};

export const getMaxBaseStatValue = (totalBaseStats: CharacterBaseStats) => {
  const maxValue = Math.max(...Object.values(totalBaseStats));

  return maxValue;
};

export const getShipDamage = (health: number, maxHealth: number): number => {
  const shipDamage = maxHealth - health;

  return shipDamage;
};

export const getRepairCost = (health: number, maxHealth: number): number => {
  const damageToRepair = getShipDamage(health, maxHealth);
  return Math.ceil(damageToRepair / REPAIR_HEALTH_PER_CREDIT);
};

export const getSwitchCost = (level: number): number => {
  const propertyInfo = getPropertyData("credits");
  const totalSwitchCost = propertyInfo.rounder(
    level * SWITCH_CREDITS_COST_PER_LEVEL
  );
  return totalSwitchCost;
};

export const isInMainArea = (location: LocationData) => {
  const { planet: currentPlanet, area: currentArea } = location;

  const currentPlanetInfo = getPlanetData(currentPlanet);
  const currentAreaInfo = getAreaData(currentArea);
  const recallAreaInfo = getAreaData(currentPlanetInfo.mainArea);

  return currentAreaInfo.slug === recallAreaInfo.slug;
};

export const isFightModeStatus = (status: FightStatuses) => {
  return [
    "preview",
    "fighting",
    "winResults",
    "loseResults",
    "loseDialog",
  ].includes(status);
};

export const hasAnimation = (
  animations: string[] = [],
  animation: string
): boolean => {
  return animations.includes(animation);
};

export const getSkillAnimation = (
  skillAnimations: {
    [key: string]: FlattenSimpleInterpolation;
  },
  activeAnimations: string[] = []
) => {
  const skill = Object.keys(skillAnimations).find((key) => {
    return activeAnimations.includes(key);
  });
  if (skill) {
    return skillAnimations[skill];
  } else {
    return null;
  }
};

export const getTotalMedallions = (level: number) => {
  let totalMedallions = 0;
  for (let i = 2; i <= level; i++) {
    const medallionsPerLevel = Math.ceil(i / 10);
    totalMedallions += medallionsPerLevel;
  }

  return totalMedallions;
};

export const getStatsTokensSpent = (
  enhancedBaseStats: CharacterBaseStats,
  ship: ShipTypes
): number => {
  const propertyInfo = getPropertyData("medallions");

  let totalTokensSpent = 0;
  const statsCosts = getShipData(ship).baseStatsCosts;

  // Figure out number of tokens to return - with differing stat costs
  for (const key in enhancedBaseStats) {
    const statSlug = key as keyof CharacterBaseStats;
    const enhancedStatValue = enhancedBaseStats[statSlug];

    const tokenCost = enhancedStatValue * statsCosts[statSlug];
    totalTokensSpent += tokenCost;
  }

  return propertyInfo.rounder(totalTokensSpent);
};

export const getStatsResetCost = (
  enhancedBaseStats: CharacterBaseStats,
  ship: ShipTypes
): number => {
  const tokensSpent = getStatsTokensSpent(enhancedBaseStats, ship);

  const resetCost = Math.ceil(tokensSpent * CREDITS_PER_STAT_TOKEN);

  return resetCost;
};

// BASE AND DERIVED STATS

export const calculateTotalBaseStats = (
  shipBaseStats: CharacterBaseStats,
  enhancedBaseStats: CharacterBaseStats
): CharacterBaseStats => {
  return {
    firepower: shipBaseStats.firepower + enhancedBaseStats.firepower,
    resilience: shipBaseStats.resilience + enhancedBaseStats.resilience,
    speed: shipBaseStats.speed + enhancedBaseStats.speed,
    precision: shipBaseStats.precision + enhancedBaseStats.precision,
    energy: shipBaseStats.energy + enhancedBaseStats.energy,
  };
};

export const calculateCurrentBaseStats = (
  totalBaseStats: CharacterBaseStats,
  weakenedBaseStats: CharacterBaseStats
): CharacterBaseStats => {
  return {
    firepower: roundOneDecimal(
      totalBaseStats.firepower - weakenedBaseStats.firepower
    ),
    resilience: roundOneDecimal(
      totalBaseStats.resilience - weakenedBaseStats.resilience
    ),
    speed: roundOneDecimal(totalBaseStats.speed - weakenedBaseStats.speed),
    precision: roundOneDecimal(
      totalBaseStats.precision - weakenedBaseStats.precision
    ),
    energy: roundOneDecimal(totalBaseStats.energy - weakenedBaseStats.energy),
  };
};

export const calculateMobBaseStats = (
  level: number,
  baseStatsModifiers: CharacterBaseStats,
  baseStatsCosts: CharacterBaseStats
): CharacterBaseStats => {
  // Mob base stats depend on level and modifiers
  // Total modifiers should add up to 15

  // Should take into account cost - 1 medallion doesn't equal 1 stat
  // Give mobs an extra medallion (mistake but don't want to redo all mob upgrades)
  const totalMedallions = getTotalMedallions(level) + 1;

  // Put together initial mob base stats object
  const mobBaseStats = Object.values(BASE_STATS).reduce(
    (mobBaseStats, baseStat) => {
      const mobBaseStat = calculateMobBaseStat(
        baseStatsModifiers[baseStat],
        baseStatsCosts[baseStat],
        totalMedallions
      );
      return {
        ...mobBaseStats,
        [baseStat]: mobBaseStat,
      };
    },
    { firepower: 0, resilience: 0, speed: 0, precision: 0, energy: 0 }
  );

  return mobBaseStats;
};

const calculateMobBaseStat = (
  modifier: number,
  cost: number,
  totalMedallions: number
): number => {
  // Add boost of 5 mob base stats to counter the character's initial 10
  const initialStatValue = modifier / 2;

  return roundOneDecimal(
    Math.min(
      initialStatValue +
        (modifier / MOB_BASE_STATS_MODIFIERS_TOTAL) * (totalMedallions / cost),
      MAX_BASE_STAT_VALUE
    )
  );
};

export const calculateInitialMobStats = (mobSlug: string) => {
  const { level, baseStatsModifiers, baseStatsCosts, installedUpgrades } =
    getMobData(mobSlug);

  const totalBaseStats = calculateMobBaseStats(
    level,
    baseStatsModifiers,
    baseStatsCosts
  );

  const derivedStats = getDerivedStatSets(
    totalBaseStats,
    totalBaseStats,
    installedUpgrades
  );

  return {
    totalBaseStats,
    derivedStats,
  };
};

// Calculate Derived Stats

export const getBaseDerivedStats = (
  baseStats: CharacterBaseStats
): CharacterDerivedStats => {
  const stats = Object.entries(DERIVED_STATS).reduce((acc, [key, value]) => {
    const statType = key as keyof CharacterDerivedStats;
    const statInfo = value as DerivedStatInfo;
    return {
      ...acc,
      [statType]: calculateBaseDerivedStat(
        statInfo,
        baseStats[statInfo.baseStat]
      ),
    };
  }, {} as any);

  return stats;
};

export const calculateBaseDerivedStat = (
  derivedStat: DerivedStatInfo,
  baseStatValue: number
): number => {
  return derivedStat.rounder(
    derivedStat.startingValue + baseStatValue * derivedStat.incrementValue
  );
};
