import { SkillModel } from "types";

import blastEchoSkillImage from "assets/skill-blastecho.svg";
import cantMissSkillImage from "assets/skill-cantmiss.svg";
import eagleEyeSkillImage from "assets/skill-eagleeye.svg";
import emergencyRepairsSkillImage from "assets/skill-emergencyrepairs.svg";
import energyBlastSkillImage from "assets/skill-energyblast.svg";
import hyperShiftSkillImage from "assets/skill-hypershift.svg";
import phantomStrikeSkillImage from "assets/skill-phantomstrike.svg";
import powerSurgeSkillImage from "assets/skill-powersurge.svg";
import rapidFireSkillImage from "assets/skill-rapidfire.svg";
import reactorOverloadSkillImage from "assets/skill-reactoroverload.svg";
import scattershotSkillImage from "assets/skill-scattershot.svg";
import shieldBreakerSkillImage from "assets/skill-shieldbreaker.svg";
import shieldBypassSkillImage from "assets/skill-shieldbypass.svg";
import shieldRestoreSkillImage from "assets/skill-shieldrestore.svg";
import shieldStormSkillImage from "assets/skill-shieldstorm.svg";
import skillsRechargeSkillImage from "assets/skill-skillsrecharge.svg";
import systemsDecaySkillImage from "assets/skill-systemsdecay.svg";

import {
  getBlastEchoDamage,
  getEagleEyeDamage,
  getEmergencyRepairHealth,
  getEnergyBlastDamage,
  getHyperShiftDamage,
  getPhantomStrikeDamage,
  getPowerSurgeDamage,
  getPressurePointDamage,
  getRapidFireDamage,
  getReactorOverloadDamage,
  getScattershotDamage,
  getShieldBreakerDamage,
  getShieldBypassDamage,
  getShieldRestoreAmount,
  getShieldStormDamage,
  getSystemsDecayDamage,
} from "libs/skill";
import {
  blastEchoSaga,
  eagleEyeSaga,
  energyBlastSaga,
  hyperShiftSaga,
  phantomStrikeSaga,
  powerSurgeSaga,
  pressurePointSaga,
  rapidFireSaga,
  reactorOverloadSaga,
  repairSaga,
  scattershotSaga,
  shieldBreakerSaga,
  shieldBypassSaga,
  shieldRestoreSaga,
  shieldStormSaga,
  skillsRechargeSaga,
  systemsDecaySaga,
} from "redux/sagas/skill";
import { formatPercentage } from "utils/formatters";
import { getNextConsecutiveTurns } from "libs/fight";

export const SKILLS: Record<string, SkillModel> = {
  shieldBreaker: {
    name: "Shield Breaker",
    slug: "shieldBreaker",
    image: shieldBreakerSkillImage,
    description:
      "Inflict greater damage later by weakening opponent shields by double the ship's current maximum weaken parts amount.",
    values: ({ attacker }) => {
      const { attackWeakenParts } = getShieldBreakerDamage(attacker.data.stats);
      return `Weaken shields by <strong>${attackWeakenParts}</strong>.`;
    },
    medallions: 0,
    isDefault: true,
    saga: shieldBreakerSaga,
    recharge: 10,
    hasAntimatterBoost: true,
  },
  scattershot: {
    name: "Scattershot",
    slug: "scattershot",
    image: scattershotSkillImage,
    description:
      "Randomly weaken an opponent component by triple the ship's current maximum weaken parts amount.",
    values: ({ attacker }) => {
      const { attackWeakenParts } = getScattershotDamage(attacker.data.stats);
      return `Weaken a random component by <strong>${attackWeakenParts}</strong>.`;
    },
    medallions: 5,
    isDefault: false,
    saga: scattershotSaga,
    recharge: 10,
    hasAntimatterBoost: true,
  },
  powerSurge: {
    name: "Power Surge",
    slug: "powerSurge",
    image: powerSurgeSkillImage,
    description:
      "Unleash a potentially devastating attack fueled by the accumulated damage that's been inflicted on the opponent. Built up maximum damage resets with each use.",
    values: ({ attacker, defender }) => {
      let maxAttackDamage = 0;
      if (defender) {
        ({ maxAttackDamage } = getPowerSurgeDamage(
          attacker.data.stats,
          defender.data.damage,
          attacker.data.skillsValues
        ));
      }
      return `Inflict <strong>0</strong> to <strong>${maxAttackDamage}</strong> damage.`;
    },
    medallions: 6,
    isDefault: false,
    saga: powerSurgeSaga,
    recharge: 12,
    hasAntimatterBoost: true,
  },
  rapidFire: {
    name: "Rapid Fire",
    slug: "rapidFire",
    image: rapidFireSkillImage,
    description:
      "Fire three quick shots in a row, inflicting the ship's current minimum damage with each shot.",
    values: ({ attacker }) => {
      const { attackDamage, attackWeakenParts } = getRapidFireDamage(
        attacker.data.stats
      );
      return `Inflict <strong>${attackDamage}</strong> damage and weaken speed by <strong>${attackWeakenParts}</strong> with each shot.`;
    },
    medallions: 0,
    isDefault: true,
    saga: rapidFireSaga,
    recharge: 8,
    hasAntimatterBoost: true,
  },
  phantomStrike: {
    name: "Phantom Strike",
    slug: "phantomStrike",
    image: phantomStrikeSkillImage,
    description:
      "Attack using a lightning fast maneuver without using up a turn, inflicting the ship's current average damage.",
    values: ({ attacker }) => {
      const { attackDamage } = getPhantomStrikeDamage(attacker.data.stats);
      return `Inflict <strong>${attackDamage}</strong> damage.`;
    },
    medallions: 5,
    isDefault: false,
    saga: phantomStrikeSaga,
    recharge: 8,
    hasAntimatterBoost: true,
  },
  hyperShift: {
    name: "Hyper Shift",
    slug: "hyperShift",
    image: hyperShiftSkillImage,
    description:
      "Use up the next consecutive turns, each of which will double the ship's current average damage, to inflict potentially devastating damage.",
    values: ({ attacker, defender }) => {
      let nextConsecutiveTurns = 0;
      if (defender) {
        nextConsecutiveTurns = getNextConsecutiveTurns(
          attacker.data.turnPriority,
          defender.data.turnPriority,
          defender.data.stats.attackSpeed
        );
      }

      const { attackDamage } = getHyperShiftDamage(
        attacker.data.stats,
        nextConsecutiveTurns
      );
      return `Inflict <strong>${attackDamage}</strong> damage by using up the ${nextConsecutiveTurns} next consecutive turns.`;
    },
    medallions: 8,
    isDefault: false,
    saga: hyperShiftSaga,
    recharge: 10,
    hasAntimatterBoost: true,
  },
  emergencyRepairs: {
    name: "Emergency Repairs",
    slug: "emergencyRepairs",
    image: emergencyRepairsSkillImage,
    description: "Quickly repair a portion of overall ship health.",
    values: ({ attacker }) => {
      const { healPercentage } = getEmergencyRepairHealth(attacker.data.stats);
      return `Repair <strong>${formatPercentage(
        healPercentage
      )}</strong> of ship health.`;
    },
    medallions: 0,
    isDefault: true,
    saga: repairSaga,
    recharge: 10,
    hasAntimatterBoost: true,
    isSupport: true,
  },
  shieldRestore: {
    name: "Shield Restore",
    slug: "shieldRestore",
    image: shieldRestoreSkillImage,
    description: "Restore a portion of the ship's shields and resilience.",
    values: ({ attacker }) => {
      const { restorePercentage } = getShieldRestoreAmount(
        attacker.data.stats,
        attacker.data.totalBaseStats
      );
      return `Restore <strong>${formatPercentage(
        restorePercentage
      )}</strong> of ship resilience.`;
    },
    medallions: 8,
    isDefault: false,
    saga: shieldRestoreSaga,
    recharge: 5,
    hasAntimatterBoost: true,
    isSupport: true,
  },
  shieldStorm: {
    name: "Shield Storm",
    slug: "shieldStorm",
    image: shieldStormSkillImage,
    description:
      "Convert deflected damage into a concentrated and devastating counterattack. Built up damage resets with each use.",
    values: ({ attacker }) => {
      const { attackDamage } = getShieldStormDamage(
        attacker.data.stats,
        attacker.data.damage,
        attacker.data.skillsValues
      );
      return `Inflict <strong>${attackDamage}</strong> damage.`;
    },
    medallions: 7,
    isDefault: false,
    saga: shieldStormSaga,
    recharge: 10,
    hasAntimatterBoost: true,
  },
  energyBlast: {
    name: "Energy Blast",
    slug: "energyBlast",
    image: energyBlastSkillImage,
    description:
      "Release a concentrated burst of antimatter energy, scaled to 5x of the ship's current antimatter base stat.",
    values: ({ attacker }) => {
      const { attackDamage } = getEnergyBlastDamage(
        attacker.data.stats,
        attacker.data.currentBaseStats
      );
      return `Inflict <strong>${attackDamage}</strong> damage.`;
    },
    medallions: 0,
    isDefault: true,
    saga: energyBlastSaga,
    recharge: 7,
    hasAntimatterBoost: true,
  },
  reactorOverload: {
    name: "Reactor Overload",
    slug: "reactorOverload",
    image: reactorOverloadSkillImage,
    description:
      "Consume half of the ship's current antimatter reserves to unleash a cataclysmic energy explosion, scaled to 10x of the ship's current antimatter base stat.",
    values: ({ attacker }) => {
      const { attackDamage } = getReactorOverloadDamage(
        attacker.data.stats,
        attacker.data.currentBaseStats
      );
      return `Inflict <strong>${attackDamage}</strong> damage.`;
    },
    medallions: 7,
    isDefault: false,
    saga: reactorOverloadSaga,
    recharge: 14,
    hasAntimatterBoost: true,
  },
  shieldBypass: {
    name: "Shield Bypass",
    slug: "shieldBypass",
    image: shieldBypassSkillImage,
    description:
      "Fire a specialized antimatter beam that bypasses opponent shields, scaled to 2x of the ship's current antimatter base stat.",
    values: ({ attacker }) => {
      const { attackDamage } = getShieldBypassDamage(
        attacker.data.stats,
        attacker.data.currentBaseStats
      );
      return `Inflict <strong>${attackDamage}</strong> guaranteed damage, if attack is not dodged.`;
    },
    medallions: 5,
    isDefault: false,
    saga: shieldBypassSaga,
    recharge: 12,
    hasAntimatterBoost: true,
    ignoreShields: true,
  },
  systemsDecay: {
    name: "Systems Decay",
    slug: "systemsDecay",
    image: systemsDecaySkillImage,
    description:
      "Release an antimatter surge that weakens all opponent systems.",
    values: ({ attacker }) => {
      const { attackWeakenParts } = getSystemsDecayDamage(attacker.data.stats);
      return `Weaken all opponent stats by <strong>${attackWeakenParts}</strong>.`;
    },
    medallions: 5,
    isDefault: false,
    saga: systemsDecaySaga,
    recharge: 10,
    hasAntimatterBoost: true,
    ignoreDodge: true,
  },
  skillsRecharge: {
    name: "Skills Recharge",
    slug: "skillsRecharge",
    image: skillsRechargeSkillImage,
    description:
      "Immediately recharge all other pilot skills with a jolt of antimatter.",
    medallions: 4,
    isDefault: false,
    saga: skillsRechargeSaga,
    recharge: 10,
    hasAntimatterBoost: false,
    isSupport: true,
  },
  pressurePoint: {
    name: "Pressure Point",
    slug: "pressurePoint",
    image: cantMissSkillImage,
    description:
      "Strike the opponent's weak spot over and over, dealing the ship's current minimum damage first, then doubling with every hit, up to 4 times.",
    values: ({ attacker }) => {
      const { attackDamage } = getPressurePointDamage(
        attacker.data.stats,
        attacker.data.skillsValues
      );
      return `Inflict <strong>${attackDamage}</strong> damage with this next shot.`;
    },
    medallions: 0,
    isDefault: true,
    saga: pressurePointSaga,
    recharge: 8,
    hasAntimatterBoost: true,
  },
  eagleEye: {
    name: "Eagle Eye",
    slug: "eagleEye",
    image: eagleEyeSkillImage,
    description: "Deliver a maximum damage attack that cannot be dodged.",
    values: ({ attacker }) => {
      const { attackDamage, attackWeakenParts } = getEagleEyeDamage(
        attacker.data.stats
      );
      return `Inflict <strong>${attackDamage}</strong> damage and weaken targeting by <strong>${attackWeakenParts}</strong>.`;
    },
    medallions: 8,
    isDefault: false,
    saga: eagleEyeSaga,
    recharge: 10,
    hasAntimatterBoost: true,
    ignoreDodge: true,
  },
  blastEcho: {
    name: "Blast Echo",
    slug: "blastEcho",
    image: blastEchoSkillImage,
    description:
      "Re-target the last opponent part to be weakened and do it again by triple the ship's current average weaken parts amount.",
    values: ({ attacker, defender }) => {
      let attackWeakenParts = 0;
      let lastWeakenedBaseStat = null;
      if (defender) {
        ({ attackWeakenParts, lastWeakenedBaseStat } = getBlastEchoDamage(
          attacker.data.stats,
          defender.data.damage
        ));
      }
      return `Weaken <strong>${
        lastWeakenedBaseStat || "last weakened part"
      }</strong> by <strong>${attackWeakenParts}</strong>.`;
    },
    medallions: 6,
    isDefault: false,
    saga: blastEchoSaga,
    recharge: 10,
    hasAntimatterBoost: true,
  },
};
