import styled from "styled-components";

import {
  DataWrapper,
  Section,
  SectionContentScroll,
  SectionGroup,
  SectionGroups,
  SectionSubtitle,
  SectionTitle,
} from "components/base/DataScreen";
import SettingsAuth from "components/ui/SettingsAuth";
import SettingsMusic from "./SettingsMusic";
import Changelog from "./Changelog";
import Roadmap from "./Roadmap";

const Link = styled.a`
  color: white;
`;

const CreditWrapper = styled.div`
  display: inline;
`;

const FeedbackWrapper = styled.div`
  display: inline;
`;

export default function SettingsScreen() {
  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Game Settings</SectionTitle>
        <SectionContentScroll>
          <SectionGroups>
            <SectionGroup>
              <SectionSubtitle>Audio</SectionSubtitle>
              <SettingsMusic />
            </SectionGroup>
            <SectionGroup>
              <SectionSubtitle>Account</SectionSubtitle>
              <SettingsAuth />
            </SectionGroup>
          </SectionGroups>
        </SectionContentScroll>
      </Section>
      <Section>
        <SectionTitle>Changelog</SectionTitle>
        <SectionContentScroll>
          <SectionGroups>
            <SectionGroup>
              <SectionSubtitle>Game Status</SectionSubtitle>
              <FeedbackWrapper>
                <div>
                  Light Voyagers is currently in <strong>alpha</strong>.
                </div>{" "}
                Have issues or suggestions? Hop into the{" "}
                <Link href="https://discord.gg/eCuSGRqGry" target="_blank">
                  Light Voyagers Discord
                </Link>
                !
              </FeedbackWrapper>
            </SectionGroup>
            <SectionGroup>
              <SectionSubtitle>Upcoming</SectionSubtitle>
              <Roadmap />
            </SectionGroup>
            <SectionGroup>
              <SectionSubtitle>Updates</SectionSubtitle>
              <Changelog />
            </SectionGroup>
            <SectionGroup>
              <SectionSubtitle>Credits</SectionSubtitle>
              <CreditWrapper>
                The title screen background is a modified version of this{" "}
                <Link
                  href="https://codepen.io/yuanchuan/pen/wZJqNK"
                  target="_blank"
                >
                  Codepen
                </Link>
                .
              </CreditWrapper>
            </SectionGroup>
          </SectionGroups>
        </SectionContentScroll>
      </Section>
    </DataWrapper>
  );
}
