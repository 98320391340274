export const COLORS = {
  HEALTH: "#47b247",
  FIREPOWER: "#f23d3d",
  RESILIENCE: "#1fa000",
  SPEED: "#d766dd",
  PRECISION: "#e0d512",
  ENERGY: "#6acee5",
  PROPERTIES: "#95e8c0",
  CREDITS: "#b899c6",
  EXPERIENCE: "#99c7c9",
  ENHANCEMENTS: "#d3c47d",
  UNLOCKED: "#43fffa",
  LEVELS: "#fff648",
  LOCATION: "#ace2cb",
  ONLINE_PLAYERS: "#39b77b",
  BASIC: "#999999",
  RATINGS: {
    PLATINUM: "#f2cdf7",
    GOLD: "#ffd731",
    SILVER: "#d8d8d8",
    BRONZE: "#c68e5c",
  },
};

export const FIGHT_DIALOG_STATUSES = ["openingDialog", "winDialog"];
