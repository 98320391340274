import React from "react";
import styled, { css } from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { exitShip, switchDataScreen } from "redux/actions";
import { getCharacter } from "redux/selectors";
import { pilotBounceAnimation } from "components/styles/animations";
import EnhanceScreen from "components/ui/EnhanceScreen";
import InventoryScreen from "components/ui/InventoryScreen";
import UpgradeScreen from "components/ui/UpgradeScreen";
import Vitals from "components/ui/Vitals";
import SettingsScreen from "components/ui/SettingsScreen";
import PilotSkills from "./PilotSkills";
import { DataScreenTypes } from "types";
import Comms from "./Comms";
import { getPilotData, getShipData } from "libs/stats";
import { DataScreen } from "components/base/DataScreen";

interface ShipInteriorWrapperProps {
  isInsideShip: boolean;
  image: string;
}

interface ShipInteriorPilotProps {
  image: string;
}

interface MenuItemProps {
  isActive: boolean;
}

const showMixin = css`
  opacity: 1;
  pointer-events: auto;
`;

const ShipInteriorWrapper = styled.div<ShipInteriorWrapperProps>`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;
  background-image: url("${(props) => props.image}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0;
  pointer-events: none;
  ${(props) => (props.isInsideShip ? showMixin : "")};
  transition: all 500ms ease-out;
`;

const ShipInteriorPilot = styled.div<ShipInteriorPilotProps>`
  width: 100%;
  height: 100%;
  background-image: url("${(props) => props.image}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  animation: ${pilotBounceAnimation} 1500ms ease-out infinite;
  pointer-events: none;
  z-index: 3;
  position: absolute;
`;

const DataScreenWrapper = styled.div`
  position: absolute;
  top: 8vh;
  bottom: 8vh;
  left: 20vw;
  right: 5vw;
  border: 1px #43fffa solid;
  border-top-width: 6px;
  box-shadow: inset 0 0 15px 5px #46d3d320;
`;

const DataScreenMenu = styled(DataScreenWrapper)`
  bottom: auto;
  left: 5vw;
  right: 80vw;
  border-right: none;
  padding: 30px 0;
  z-index: 1;
  text-align: right;
  background-color: #0a1616;
  color: white;
  opacity: 0.95;
`;

const activeMixin = css`
  background-color: #00000050;
  color: #43fffa;
  border-color: #43fffa;
`;

const MenuItem = styled.div<MenuItemProps>`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  padding: 10px 25px;
  box-sizing: border-box;
  border: 1px transparent solid;
  border-left: none;
  border-right: none;
  ${(props) => props.isActive && activeMixin};

  &:hover {
    background-color: #00000050;
  }
`;

const ExitButton = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  padding: 10px 25px;
  box-sizing: border-box;
  margin-top: 30px;
  background-color: #ffffff10;

  &:hover {
    background-color: #00000050;
  }
`;

interface ShipScreenData {
  slug: DataScreenTypes;
  name: string;
  component: React.ReactNode;
}

const SCREENS: ShipScreenData[] = [
  {
    slug: "vitals",
    name: "Vitals",
    component: <Vitals />,
  },
  {
    slug: "stats",
    name: "Ship Stats",
    component: <EnhanceScreen canEnhance={false} />,
  },
  {
    slug: "upgrades",
    name: "Ship Upgrades",
    component: <UpgradeScreen canInstall={false} />,
  },
  {
    slug: "inventory",
    name: "Supplies",
    component: <InventoryScreen />,
  },
  {
    slug: "skills",
    name: "Pilot Skills",
    component: <PilotSkills canTrain={false} />,
  },
  {
    slug: "comms",
    name: "Pilot Network",
    component: <Comms />,
  },
  {
    slug: "settings",
    name: "Settings",
    component: <SettingsScreen />,
  },
];

export default function ShipInterior() {
  const dispatch = useDispatch();
  const {
    data: {
      ship,
      pilot,
      ui: { isInsideShip, dataScreen },
    },
  } = useSelector(getCharacter);
  const { images: shipImages } = getShipData(ship);
  const { images: pilotImages } = getPilotData(pilot);

  const currentScreen = SCREENS.find((screen) => screen.slug === dataScreen);

  return (
    <ShipInteriorWrapper
      image={shipImages.interior}
      isInsideShip={isInsideShip}
    >
      <ShipInteriorPilot image={pilotImages.interior} />
      <DataScreenWrapper>
        <DataScreen>{!!currentScreen && currentScreen.component}</DataScreen>
      </DataScreenWrapper>
      <DataScreenMenu>
        {SCREENS.map((screen) => (
          <MenuItem
            key={screen.slug}
            isActive={dataScreen === screen.slug}
            onClick={() => dispatch(switchDataScreen(screen.slug))}
          >
            {screen.name}
          </MenuItem>
        ))}
        <ExitButton onClick={() => dispatch(exitShip())}>Exit</ExitButton>
      </DataScreenMenu>
    </ShipInteriorWrapper>
  );
}
